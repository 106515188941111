import { PureComponent } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import styles from './index.module.css'

class index extends PureComponent<{ isLoading: boolean }> {
  render() {
    const { isLoading } = this.props

    return (
      <>
        {
          isLoading &&
          <div className={styles.box}>
            <CircularProgress />
          </div>
        }
      </>
    )
  }
}

export default index
