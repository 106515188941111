import { Component } from 'react'
import { GM_QQ } from 'src/utils/config'
import { getStore } from 'src/utils/store'
import { getUrlParam } from 'src/utils/utils'
import customerImg from '../../../assets/customer.jpg'
import styles from './FloatmodCustomerNav.module.css'

type IProps = {
  exchange_group: string[]
}

export default class FloatmodCustomerNav extends Component<IProps> {

  render() {
    const { exchange_group } = this.props
    const qq = getStore(GM_QQ)
    const platform = parseInt(getUrlParam('platform') || '0')

    const handleClick = () => {
      window.open(`https://wpa1.qq.com/4Z160ESb?_type=wpa&qidian=true`)
    }

    if (platform === 0) {
      return (
        <div className={styles.float_ios_customer}>
          {/* <div className={styles.float_ios_customer_title}>礼包福利</div> */}
          <div className={styles.float_ios_customer_content}>下载APP获礼包，为避免账号密码丢失，请及时下载APP进行游戏！！！</div>
          <div className={styles.float_ios_customer_content}>下载并使APP玩游戏，即可获得专享“APP大礼包”一份，点击“前往客服”，回复“APP礼包”领取礼包码，且下载APP！</div>
          <div className={styles.float_ios_concat}>
            <span className={styles.float_customer_label}>
              <span className={styles.customer_label}>客服QQ：</span>
              <span className="phone">{ qq }</span>
            </span>
            {
              exchange_group && exchange_group?.length > 0 && 
              <span className={styles.float_customer_label}>
                <span className={styles.customer_label}>玩家QQ群：</span>
                <span className={styles.customer_palyer}>
                  { 
                    exchange_group.map((item) => (
                      <span key={item}>{ item }</span>
                    ))
                  }
                </span>
              </span>
            }
          </div>
          <div className={styles.float_ios_customer_btn} onClick={handleClick}>前往客服</div>
        </div>
      )
    }
    return (
      <div className={styles.flex_center_box}>
        <img className={styles.float_customer} src={customerImg} alt='' />
        <span className={styles.float_customer_label}>微信扫一扫，或者截图后放入微信</span>
        <span className={styles.float_customer_label}>长按二维码识别</span>
        <span className={styles.float_customer_label}>
          <span className={styles.customer_label}>客服QQ：</span>
           <span className="phone">800183043</span>
        </span>
        {
          exchange_group && exchange_group?.length > 0 && 
          <span className={styles.float_customer_label}>
            <span className={styles.customer_label}>玩家QQ群：</span>
            <span className={styles.customer_palyer}>
              { 
                exchange_group.map((item) => (
                  <span key={item}>{ item }</span>
                ))
              }
            </span>
          </span>
        }
      </div>
    )
  }
}
