import {  ACTIVITY_URL, ANDROID_ID, APP_ID, BIND_PHONE, CHECK_BIND_POPUP, CODE, EXCHANGE_GROUP, GAME_ID, GAME_IFRAME_URL, GAME_SID, GAME_SID_NAME, GUIDE_URL, IDENTITY, IDFA, IDFV, IMEI, IMEI2, ISP, IS_ACTIVITY_URL, IS_ADULT, IS_WECHAT_MINI, MODEL, NAME_VERIFY, NETWORK, OAID, OPEN_ID, OS, RED_BAG_ACTIVE, REGISTER_DISABLED, ROLE_ID, ROLE_NAME, SIGN_KEY, TOKEN, URL_OPTENID, USERNAME, USER_INFOS } from './config'
import { getStore, removeStore, getLocalStorage, setLocalStorage } from './store'
import md5 from 'js-md5'

// 处理微信公共参数
function handleGetWechatUrlParams(param: any, constant: string, urlParam: string, otherUrlParam?: string) {
  const value = getUrlParam(urlParam) || (otherUrlParam && getUrlParam(otherUrlParam)) || getLocalStorage(constant)
  if (value) {
    setLocalStorage(constant, value)
    param[urlParam] = value
  } else {
    delete param[urlParam]
  }
}

// 处理url公共参数
function handleGetUrlParams(param: any, constant: string, urlParam: string, otherUrlParam?: string) {
  // const value = getUrlParam(urlParam) || (otherUrlParam && getUrlParam(otherUrlParam)) || getLocalStorage(constant)
  const value = getUrlParam(urlParam) || (otherUrlParam && getUrlParam(otherUrlParam))
  if (value) {
    // setLocalStorage(constant, value)
    param[urlParam] = value
  } else {
    delete param[urlParam]
  }
}

// 处理公共请求参数
export function handleParam(param: any = {}) {
  delete param.sign
  const openid = getUrlParam('openid') || getLocalStorage(OPEN_ID)
  const isWechatMini = getUrlParam('isWechatMini')  || getStore(IS_WECHAT_MINI)
  param.uniq = getStore(RED_BAG_ACTIVE)
  param.code = getLocalStorage(CODE) || ''
  param.game_id = param?.game_id || param?.gameId || getStore(GAME_ID)
  param.channel = getUrlParam('channel') || 'platform'
  param.sub_channel = ''
  param.time = new Date().getTime()
  openid ? param.openid = openid : delete param.openid

  // 平台参数处理
  // let platform = getUrlParam('platform') || 0
  // if (handleIsIOS()) platform = 1
  // if (handleIsAndroid()) platform = 3
  // if (handleIsWechat()) platform = 0
  let platform = getUrlParam('platform') || '0'
  const platformArr = [0, 1, 3]
  let platformNum = parseInt(platform) // 转为数字
  
  // 当平台参数不是数字
  if (isNaN(platformNum) || !(platformArr.includes(platformNum))) {
    const arr = platform.split('')
    let isLock = false
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i]
      const itemNum = parseInt(item)
      if (!isNaN(itemNum) && platformArr.includes(itemNum) && !isLock) {
        isLock = true
        platform = itemNum.toString()
      }
      if (!isLock && i === arr.length - 1) {
        platform = '0'
      }
    }
  }
  param.platform = platform

  // 小程序环境获取支付参数
  if ((window as any).__wxjs_environment) {
    handleGetWechatUrlParams(param, APP_ID, 'appId', 'app_id')
  }

  // IOS额外参数
  handleGetUrlParams(param, IDFA, 'idfa')
  handleGetUrlParams(param, IDFV, 'idfv')
  handleGetUrlParams(param, IMEI, 'imei')
  handleGetUrlParams(param, IMEI2, 'imei2')
  handleGetUrlParams(param, OAID, 'oaid')
  handleGetUrlParams(param, ANDROID_ID, 'androidid')
  handleGetUrlParams(param, MODEL, 'model')
  handleGetUrlParams(param, NETWORK, 'network')
  handleGetUrlParams(param, ISP, 'isp')
  handleGetUrlParams(param, OS, 'os')
  
  if (isWechatMini) {
    param.isWechatMini = isWechatMini
    setLocalStorage(IS_WECHAT_MINI, isWechatMini)
  } else {
    delete param.isWechatMini
  }

  let sign = ``
  Object.keys(param).sort().forEach(item => {
    sign += `${item}=${param[item]}&`
  })

  param.sign = md5(`${sign}${SIGN_KEY}`)

  return param
}

// 清除store
export function handleClearStore() {
  removeStore(ROLE_ID)
  removeStore(ROLE_NAME)
  removeStore(GAME_ID)
  removeStore(GAME_SID)
  removeStore(GAME_SID_NAME)
  removeStore(TOKEN)
  removeStore(IDENTITY)
  removeStore(BIND_PHONE)
  removeStore(OPEN_ID)
  removeStore(URL_OPTENID)
  removeStore(GAME_IFRAME_URL)
  removeStore(RED_BAG_ACTIVE)
  removeStore(EXCHANGE_GROUP)
  removeStore(REGISTER_DISABLED)
  removeStore(NAME_VERIFY)
  removeStore(IS_ADULT)
  removeStore(ACTIVITY_URL)
  removeStore(GUIDE_URL)
  removeStore(IS_ACTIVITY_URL)
  removeStore(CHECK_BIND_POPUP)
}

// 获取游戏ID
export function handleGetGameId(props: any) {
  const { search, pathname } = props.location
  const { history } = props
  const gameStore = getStore(GAME_ID)
  let gameId = ''
  const searchs = search.split('?')[1]
  const isGameId = (value: any) => value === 'gameId' || value === 'game_id' || value === 'gameID'
  // 判断是否有多个索引条件
  if (searchs) {
    if (searchs.includes('&')) {
      const searchItems = searchs.split('&')
      searchItems.forEach((item: any) => {
        const keyValue = item.split('=')
        if (isGameId(keyValue[0])) {
          gameId = keyValue[1]
        }
      })
    } else {
      const keyValue = searchs.split('=')
      if (isGameId(keyValue[0])) {
        gameId = keyValue[1]
      }
    }
  }
  // 没有gameID
  if (!gameId && pathname !== '/login') {
    handleClearStore()
    history.push(`/login${search}`)
    return ''
  }
  // 缓存和游戏ID不同时
  if (gameStore && gameStore !== gameId) {
    handleClearStore()
    history.push(`/login${search}`)
    return gameId
  }
  return gameId
}

// 获取当前用户数据
export function handleGetUserInfo(usernameInput?: string): IUserInfos {
  const username = usernameInput || getLocalStorage(USERNAME)
  const user_infos: IUserInfos[] = getLocalStorage(USER_INFOS) ?
    JSON.parse(getLocalStorage(USER_INFOS)) : []
  const empty = { username: '', password: '', days: 0, amount: 0, time: '' }
  if (!username || !user_infos) return empty

  for (let key in user_infos) {
    if (user_infos?.[key]?.username === username) {
      return user_infos[key]
    }
  }

  return empty
}

// 是否显示手机弹窗
export type IPhonePopupType = 'days_after_register' | 'amount'
export function handlePhonePopup(type: IPhonePopupType, value?: number): number {
  const username = getLocalStorage(USERNAME)
  const user_infos: IUserInfos[] = getLocalStorage(USER_INFOS) ?
    JSON.parse(getLocalStorage(USER_INFOS)) : []

  for (let key in user_infos) {
    if (user_infos?.[key]?.username === username) {
      const { amount, days } = user_infos[key]
      switch (type) {
        case 'amount': {
          // 当有值时更新当前用户数据
          if (value !== null && value !== undefined) {
            user_infos[key].amount = value
            setLocalStorage(USER_INFOS, JSON.stringify(user_infos))
          }
          return amount
        }

        case 'days_after_register': {
          // 当有值时更新当前用户数据
          if (value !== null && value !== undefined) {
            user_infos[key].days = value
            setLocalStorage(USER_INFOS, JSON.stringify(user_infos))
          }
          return days
        }
      }
    }
  }

  return 0
}

// 判断是否是PC
export function handleIsPC() {
  const userAgentInfo = navigator.userAgent;
  const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"]
  let flag = true;  
  for (let v = 0; v < Agents.length; v++) {  
    if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
  }
  return flag;  
}

// 判断是否是微信
export function handleIsWechat() {
  let isWechat = false

  if (navigator.userAgent.includes('WindowsWechat') ||
    navigator.userAgent.includes('Wechat') ||
    navigator.userAgent.includes('Weixin') ||
    navigator.userAgent.includes('MicroMessenger')
  ) {
    isWechat = true
  }

  return isWechat
}

// 判断是否是安卓
export function handleIsAndroid() {
  let isAndroid = false
  const u = navigator.userAgent;

  if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
    isAndroid = true
  }

  return isAndroid
}

// 判断是否是IOS
export function handleIsIOS() {
  const phone = navigator.userAgent;
  const isIOS = !!phone.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

  return isIOS
}

// 处理iframe子传父数据
export function handleIframeParent(action: any, info: any) {
  const phone = navigator.userAgent;
  const isIOS = !!phone.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  const isAndroid = phone.indexOf('Android') > -1 || phone.indexOf('Adr') > -1; //android终端
  
  if (window && window.parent) {
    window.parent.postMessage({ action, info }, '*')
  }

  if (isIOS && (window as any).webkit) {
    return (window as any).webkit.messageHandlers.SDKMessage.postMessage({ action, info })
  }
  if (isAndroid && (window as any).Android) {
    return (window as any).Android.SDKMessage(JSON.stringify({ action, info }))
  }
}

// 生成随机数
export function handleRandom(e: number) {  
  e = e || 32
  // let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  let nums = ''
  for (let i = 0; i < e; i++) {
    let id = parseInt((Math.random() * 61).toString())
    nums += chars[id]
  }
  return nums
}

// 防抖
export function debounce(func: Function, delay: number, thisArg?: any) {
  var timer: number;
  return function (...args: any[]) {
    if (timer) {
      window.clearTimeout(timer);
    }
    timer = window.setTimeout(function () {
      func.apply(thisArg, args);
    }, delay)
  }
}

// 手机验证
export function validMobile(phone: string) {
  const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
  return reg.test(phone)
}

//获取地址栏url后的参数值
export function getUrlParam(key: string) {
  let value: false | string = false
  const href = window.location.href
  const url = href.split("?")
  if(url.length <= 1){
     return value
  }
  const params = url[1].split("&")
  
  for(let i = 0; i < params.length; i++){
     const param = params[i].split("=")
     if(key === param[0]){
      value = param[1]
      return param[1]
    }
  }

  return value
}

// 计算两个时间之间的时间差 多少天时分秒
export function intervalTime(dateBegin: number): string {// di作为一个变量传进来
  // 如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
  let dateEnd = new Date().getTime(); // 获取当前时间
  let dateDiff = dateEnd - dateBegin; // 时间差的毫秒数
  let dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); // 计算出相差天数
  let leave1 = dateDiff % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000))// 计算出小时数
  // 计算相差分钟数
  let leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000))// 计算相差分钟数
  // 计算相差秒数
  let leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
  let seconds = Math.round(leave3 / 1000)
  // 计算月份
  let month = Math.round(dayDiff / 30)
  let day = dayDiff % 30
  // console.log(" 相差 "+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒")
  if (month > 0) {
    return `${month}月前`
  }
  if (day > 0) {
    return `${day}天前`
  }
  if (hours > 0) {
    return `${hours}小时前`
  }
  if (minutes > 0) {
    return `${minutes}分钟前`
  }
  if (seconds > 0) {
    return `${seconds}秒前`
  }
  return ''
}

// 时间格式
export function handleFilterDate(date: number) {
  return date > 0 ? date : `0${date}`
}

// 计算剩余时间
export function dateDiff(dateEnd: string) {
  let today = new Date().getTime(); // 获取当前时间
  let dateDiff = parseInt(dateEnd) * 1000 - today; // 时间差的毫秒数
  let dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); // 计算出相差天数
  let leave1 = dateDiff % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000))// 计算出小时数
  // 计算相差分钟数
  let leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000))// 计算相差分钟数
  // 计算相差秒数
  let leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
  let seconds = Math.round(leave3 / 1000)
  // 计算月份
  let month = Math.round(dayDiff / 30)
  let day = dayDiff % 30

  return`${month}个月${day}天${hours}时${minutes}分${seconds}秒`
}

// IOS禁止缩放
export function handleGesture() {
  // 禁止双击放大
  var lastTouchEnd = 0;
  document.addEventListener('touchstart', function (event) {
    if(event.touches.length > 1){
      event.preventDefault();
    }
  });
  document.addEventListener('touchend', function (event) {
    var now = (new Date()).getTime();
    if(now - lastTouchEnd <= 300){
      event.preventDefault();
    }
    lastTouchEnd = now;
  }, false);
  // 禁止双指缩放
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}