import { PureComponent } from 'react'
import { getLocalStorage, getStore } from '../../../utils/store'
import styles from './Pay.module.css'
import AlipayImg from '../../../assets/alipay.png'
import WechatImg from '../../../assets/official_wechat.png'
import Loading from '../../../components/Loading'
import { handleIframeParent, handleIsIOS, handleIsPC, handleIsWechat } from '../../../utils/utils'
import { GAME_NAME, USERNAME } from 'src/utils/config'
import QRCode from 'qrcode.react'
import API from '../../../services/api'
const wx = require('weixin-js-sdk')

type IProps = {
  isPay: boolean;
  amount: number;
  interfaces: any;
  payParams: any;
  openID: string;
  onRef: (ref: any) => void;
  close: () => void;
  handleError: (response: any) => void;
  handleOrderState: (id: string) => void;
}

type IState = {
  isPay: boolean;
  isLoading: boolean;
  isPayWechatPC: boolean;
  payURL: string;
  payParams: any;
  pay_url: any;
}

class Pay extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isPay: false,
      isLoading: false,
      isPayWechatPC: false,
      payURL: '',
      payParams: {},
      pay_url: {}
    }

    this.handlePayFun = this.handlePayFun.bind(this)
    this.handleQrcodeClose = this.handleQrcodeClose.bind(this)
    this.handleWechat = this.handleWechat.bind(this)
    this.handleAlipay = this.handleAlipay.bind(this)
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  // // 获取小程序支付参数
  // async handleGetPayParams(payParams: any) {
  //   const { isPay } = this.props

  //   // 如果首次进入是微信小程序
  //   if (isPay && (window as any).__wxjs_environment) {
  //     const newParams = Object.assign({}, payParams)
  //     newParams.interface = 'official_wechat'
  //     const pay_url: any = await this.handleRechargeQRCode(newParams)
  //     const { close } = this.props
  //     this.setState({ pay_url })
  //     close()

  //     // 小程序处理
  //     const { timeStamp, nonceStr, signType, paySign } = pay_url
  //     const packages = pay_url.package // package保留字
  //     this.handleWechat(pay_url)
  //     wx.miniProgram.navigateTo({ url: `/pages/pay/index?timeStamp=${timeStamp}&nonceStr=${nonceStr}&package=${packages}&signType=${signType}&paySign=${paySign}` })
  //   }
  // }
  
  // 支付方式
  async handlePayFun(type: string, payParams?: any) {
    const newParams = Object.assign({}, payParams || this.props.payParams)
    newParams.interface =  type
    const pay_url: any = await this.handleRechargeQRCode(newParams)

    // IOS环境
    if (handleIsIOS() && !handleIsWechat()) {
      return setTimeout(() => {
        window.location.href = pay_url
        handleIframeParent('handlePayUrl', pay_url)
      }, 0);
    }
    
    // 小程序环境
    if ((window as any).__wxjs_environment) {
      const { timeStamp, nonceStr, signType, paySign, appId } = pay_url
      const packages = encodeURIComponent(pay_url.package) // package保留字
      this.handleWechat(pay_url)
      wx.miniProgram.navigateTo({ url: `/pages/pay/index?timeStamp=${timeStamp}&nonceStr=${nonceStr}&package=${packages}&signType=${signType}&paySign=${paySign}&appId=${appId}` })
    }

    // 非小程序环境
    if (!(window as any).__wxjs_environment) {
      if (newParams.interface === 'alipay' || newParams.interface === 'xhepay_alipay') {
        // 支付宝自动跳转
        this.handleAlipay(pay_url, newParams.interface)
      } else {
        this.handleWechat(pay_url)
      }
    }
  }

  // 关闭二维码
  handleQrcodeClose() {
    this.setState({ isPayWechatPC: false })
  }

  // 生成支付二维码
  handleRechargeQRCode(param: any) {
    const { openID, close } = this.props
    param.username = getLocalStorage(USERNAME)
    this.setState({ isLoading: true })
    if (handleIsWechat()) param.openid = openID

    return new Promise((resolve, reject) => {
      let funcs = API.pay_order

      // 小程序环境
      if ((window as any).__wxjs_environment) {
        param.interface = 'mini_program_wechat'
        funcs = API.pay_order_mini
      }

      funcs(param).then((response: any) => {
        const res = response.data
        this.setState({ isLoading: false })
        if (res?.code === 200) {
          // 论查查看订单
          const { pay_url } = res.data
          close()
          resolve(pay_url)
        } else {
          this.props.handleError(res)
        }
        reject('')
      }).catch(() => {
        this.setState({ isLoading: false })
        reject('')
      }).finally(() => {
        this.setState({ isLoading: false })
      })
    })
  }

  // 处理微信
  handleWechat(pay_url: any) {
    this.setState({ payURL: pay_url })
    const that = this

    if (handleIsWechat()) {
      const { appId, timeStamp, nonceStr, signType, paySign } = pay_url
      const packages = pay_url.package // package保留字
      const onBridgeReady: any = () => {
        (window as any).WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId": appId,     //公众号名称，由商户传入
            "timeStamp": timeStamp.toString(),         //时间戳，自1970年以来的秒数
            "nonceStr": nonceStr, //随机串
            "package": packages,
            "signType": signType,         //微信签名方式：
            "paySign": paySign //微信签名
          },
          function(res: any){
            // if (navigator.userAgent.includes('miniprogram')) {
            //   alert(JSON.stringify(res))
            // }
            if (
              res.err_msg === "get_brand_wcpay_request:ok" ||
              res.err_msg === "get_brand_wcpay_request:fail" ||
              res.err_msg === "get_brand_wcpay_request:cancel"
            ) {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              return that.props.close()
            }
            //  else {
            //   const question = window.confirm("支付失败，是否重新支付");
            //   if(question){
            //     pay();
            //   }
            //   if (res.err_msg === "get_brand_wcpay_request:ok" ) {
            //     // 使用以上方式判断前端返回,微信团队郑重提示：
            //     // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            //     that.props.close()
            //   } else {
            //     const question = window.confirm("支付失败，是否重新支付");
            //     if(question){
            //       pay();
            //     }
            //   }
            // }
          });
      }

      const pay = () => {
        if (typeof (window as any).WeixinJSBridge === "undefined"){
          if( document.addEventListener ){
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
          }else if ((document as any).attachEvent){
            (document as any).attachEvent('WeixinJSBridgeReady', onBridgeReady);
            (document as any).attachEvent('onWeixinJSBridgeReady', onBridgeReady);
          }
        }else{
          onBridgeReady();
        }
      }
      
      // 小程序退出
      if (!((window as any).__wxjs_environment)) pay()

      return
    }

    if (!handleIsPC() && !handleIsWechat()) {
      return window.open(pay_url)
    }

    if (handleIsPC()) {
      this.setState({ isPayWechatPC: true })
    }
  }

  // 处理支付宝
  handleAlipay(pay_url: string, type: string) {
    if (type === 'xhepay_alipay' && handleIsPC()) {
      const start = pay_url.indexOf("http")
      pay_url = pay_url.substring(start, pay_url.length)
      const end = pay_url.indexOf("' ")
      pay_url = pay_url.substring(0, end)
      this.setState({ payURL: pay_url })
      return this.setState({ isPayWechatPC: true })
    }

    // 添加之前先删除一下，如果单页面，页面不刷新，添加进去的内容会一直保留在页面中，二次调用form表单会出错
    let divForm = document.getElementsByTagName('divform')
    if (divForm.length) {
      document.body.removeChild(divForm[0])
    }
    const div = document.createElement('divform')
    div.innerHTML = pay_url // data就是接口返回的form 表单字符串
    document.body.appendChild(div)
    document.forms[0].setAttribute('target', '_blank') // 新开窗口跳转
    document.forms[0].submit()
  }

  render() {
    const { interfaces, amount, close } = this.props
    const { isLoading, isPayWechatPC, payURL } = this.state

    return (
      <>
        {
          this.props.isPay &&
            <div  className={styles.dialog}>
              <div className={styles.box}>
                <div className={styles.dialog_close} onClick={close}>
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                  </svg>
                </div>
                <div className={styles.title}>支付</div>
                <div className={styles.content}>
                  <div>
                    <span className={styles.lable}>【{ getStore(GAME_NAME) || process.env.REACT_APP_COMPANY_NAME }】</span>
                    <span>{ getLocalStorage(USERNAME) }</span>
                  </div>
                  <p>￥{ amount || 0 }</p>

                  <div className={styles.pay_function}>
                    {
                      interfaces?.length > 0 && interfaces.indexOf('alipay') !== -1 &&
                      <div
                        className={`${styles.pay_function_item} ${styles.alipay}`}
                        onClick={() => this.handlePayFun('alipay')}
                      >
                        <img className={styles.pay_img} src={AlipayImg} alt="支付宝" />
                        <span>支付宝</span>
                      </div>
                    }
                    {
                      interfaces?.length > 0 && interfaces.indexOf('xhepay_alipay') !== -1 &&
                      <div
                        className={`${styles.pay_function_item} ${styles.alipay}`}
                        onClick={() => this.handlePayFun('xhepay_alipay')}
                      >
                        <img className={styles.pay_img} src={AlipayImg} alt="支付宝" />
                        <span>支付宝</span>
                      </div>
                    }
                    {
                      interfaces?.length > 0 && interfaces.indexOf('official_wechat') !== -1 &&
                      <div
                        className={`${styles.pay_function_item} ${styles.wechat}`}
                        onClick={() => this.handlePayFun('official_wechat')}
                      >
                        <img className={styles.pay_img} src={WechatImg} alt="微信" />
                        <span>微信</span>
                      </div>
                    }
                    {
                      interfaces?.length > 0 && interfaces.indexOf('provider_wechat') !== -1 &&
                      <div
                        className={`${styles.pay_function_item} ${styles.wechat}`}
                        onClick={() => this.handlePayFun('provider_wechat')}
                      >
                        <img className={styles.pay_img} src={WechatImg} alt="微信" />
                        <span>微信</span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          {
            isPayWechatPC &&
            <div className={`${styles.dialog} ${styles.qr_dialog}`}>
              <div className={styles.box}>
                <div className={styles.dialog_close} onClick={() => this.handleQrcodeClose()}>
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                  </svg>
                </div>
                <div className={styles.title}>游戏充值</div>
                <div className={styles.content}>
                  <div>
                    <span className={styles.lable}>【{process.env.REACT_APP_COMPANY_NAME}】</span>
                    <span>{ getLocalStorage(USERNAME) }</span>
                  </div>
                  <p>￥{ amount || 0 }</p>
                  <div className={styles.qrcode}>
                    <QRCode
                      value={payURL}
                    />
                  </div>
                  <div className={`${styles.prompt} ${styles.small}`}>温馨提示：经官方查证大量不法分子在游戏前期会冒充玩家或以宣传福利的方式套取用户微信等联系方式，为了您的账号安全，请勿随意添加他人微信。</div>
                  <div className={styles.prompt}>温馨提示: 每一个二维码仅能支付一次, 支付成功后请关闭本窗口, 否则有可能充值不到账</div>
                </div>
              </div>
            </div>
          }

          <Loading isLoading={isLoading} />
        </>
    )
  }
}

export default Pay
