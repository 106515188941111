import axios from 'axios'
import { TOKEN } from './config'
import { getStore } from './store'

const prefixUrl = process.env.REACT_APP_BASE_API

export type IReponse = {
  code: number;
  message: string;
  data: any;
}

const service = axios.create({
  baseURL: prefixUrl as string,
  timeout: 30000
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${getStore(TOKEN)}`
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data

    if (res?.code !== 200) {
      console.log('错误信息:', res.message)
      // alert(res.message || '服务器错误!')
    }

    return response
  },
  (error) => {
    console.log('error:',error)
    // if (error.response) {
    //   switch (error.response.status) {
    //     case 401:
    //       alert('登录失效!')
    //       console.log('登录失效')
    //       break

    //     default:
    //       break
    //   }
    // }
    return Promise.reject(error)
  }
)

export default service
