import ReactDOM from 'react-dom';
import './index.css';
// import '@babel/polyfill';
import Root from './router';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from "react-router-dom";

ReactDOM.render(
    <Router>
      <Root />
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
