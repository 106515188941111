import { Color } from '@material-ui/lab';
import { Component } from 'react'
import { BIND_PHONE, GAME_ID, USERNAME } from 'src/utils/config';
import { getLocalStorage, getStore, setStore } from 'src/utils/store';
import { handleGetGameId, validMobile } from 'src/utils/utils';
import styles from './FloatmodBindPhone.module.css'
import API from '../../../services/api'

interface IProps {
  zIndex?: number;
  handleError: (response?: any) => void;
  handleLoading: (isLoading: boolean) => void;
  handleMessage: (content: string, type?: Color) => void;
  handleDialog: (isShow: boolean) => void;
  handleCloseBindPhone?: (isDialog: boolean) => void;
}

interface IState {
  phone: string;
  isSendVerify: boolean;
  sendVerifyContent: string;
  verfity: string;
  time: any;
}

export default class FloatmodRealname extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      phone: '',
      isSendVerify: false,
      sendVerifyContent: '',
      verfity: '',
      time: null,
    }

    this.handleSendVerify = this.handleSendVerify.bind(this)
    this.handleBindPhone = this.handleBindPhone.bind(this)
    this.handleCountdown = this.handleCountdown.bind(this)
  }

  componentWillUnmount() {
    clearInterval(this.state.time)
    this.setState({ time: null })
  }
  
  // 发送验证码
  handleSendVerify() {
    const { phone } = this.state
    const { handleError, handleLoading, handleMessage } = this.props
    let gameId = getStore(GAME_ID)
    if (!gameId) {
      gameId = handleGetGameId(this.props)
      if (!gameId) {
        return handleMessage('未获取到游戏ID，请重试', 'warning')
      }
    }
    if (!phone) {
      return  handleMessage('请输入手机号', 'warning')
    }
    if (!validMobile(phone.toString())) {
      return handleMessage('请输入正确手机号', 'warning')
    }
  
    const params = { type: 'binding', phone }
    handleLoading(true)
    API.phone_verify(params).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        handleMessage('发送成功', 'success')
        this.handleCountdown()
      } else {
        handleError(res)
      }
      handleLoading(false)
    }).catch(() => {
      handleLoading(false)
      handleError()
    })
  }

  // 倒计时60秒
  handleCountdown(date = 60000) {
    let num = date / 1000
    this.setState({
      isSendVerify: true,
      sendVerifyContent: `等待${num}s`,
      time: setInterval(() => {
        num--
        this.setState({ sendVerifyContent: `等待${num}s` })
        if (num === 0) {
          clearInterval(this.state.time)
          this.setState({ isSendVerify: false })
        }
      }, 1000)
    })
  }

  // 绑定手机
  handleBindPhone() {
    const { phone, verfity } = this.state
    const {
      handleError,
      handleLoading,
      handleMessage,
      handleDialog,
      handleCloseBindPhone 
    } = this.props
    const username = getLocalStorage(USERNAME)
    let gameId = getStore(GAME_ID)
    if (!gameId) {
      gameId = handleGetGameId(this.props)
      if (!gameId) {
        return handleMessage('未获取到游戏ID，请重试', 'warning')
      }
    }
    if (!phone) {
      return handleMessage('请输入手机号', 'warning')
    }
    if (!validMobile(phone.toString())) {
      return handleMessage('请输入正确手机号', 'warning')
    }
    if (!verfity) {
      return handleMessage('请输入验证码', 'warning')
    }

    const params = { phone, verify_code: verfity, username }
    handleLoading(true)
    API.phone_binding(params).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        setStore(BIND_PHONE, 'true')
        handleDialog(false)
        handleCloseBindPhone && handleCloseBindPhone(false)
        handleMessage(res.message || '成功!', 'success')
      } else {
        handleError(res)
      }

      handleLoading(false)
    }).catch(() => {
      handleLoading(false)
      handleError()
    })
  }

  render() {
    const { sendVerifyContent, isSendVerify, phone, verfity } = this.state
    const { zIndex, handleDialog } = this.props

    return (
      <div className={`${styles.dialog} ${styles.user_dialog}`} style={{ zIndex: zIndex || 10 }}>
        <div className={styles.box}>
          <div className={styles.dialog_close} onClick={() => handleDialog(false)}>
            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
            </svg>
          </div>
          <div className={styles.title}>
            <span>绑定手机</span>
          </div>
          <div className={styles.content}>
            <div className={styles.user_row}>
              <input
                className={styles.input}
                value={phone}
                type="text"
                placeholder="请输入手机号"
                onChange={e => this.setState({ phone: e.target.value})}
              />
              {
                !isSendVerify &&
                <span onClick={this.handleSendVerify}>获取验证码</span>
              }
              {
                isSendVerify &&
                <span className={styles.verfity_time}>{ sendVerifyContent }</span>
              }
            </div>
            <div className={styles.user_row}>
              <input
                className={styles.input}
                value={verfity}
                type="text"
                placeholder="请输入验证码"
                onChange={e => this.setState({ verfity: e.target.value})}
              />
            </div>
            <div className={styles.user_btn_row}>
              <button className={styles.btn} onClick={this.handleBindPhone}>绑定手机</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}