import React, { Component } from 'react'
import axios from 'axios'
import { GUIDE_URL, TOKEN } from 'src/utils/config'
import { getStore } from 'src/utils/store'
import { getUrlParam } from 'src/utils/utils'
import styles from './FloatmodGuide.module.css'

interface IData {
  id: string;
  title: string;
  status: '0' | '1';
}

interface IProps {
  handleError: (response?: any) => void;
  handleLoading: (isLoading: boolean) => void;
}
interface IState {
  data: IData[];
  gameId: string;
  prefixUrl: string;
}

export default class FloatmodActivity extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      gameId: this.handleGetGameId(),
      prefixUrl: '',
      data: []
    }

    this.handleGetData = this.handleGetData.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    this.handleGetData()
  }

  // 获取数据
  handleGetData(): void {
    const { handleError, handleLoading } = this.props
    const { gameId } = this.state
    let prefixArr = (process.env.REACT_APP_BASE_API as string).split('/')
    prefixArr.length = prefixArr?.length - 1
    const prefixUrl = prefixArr.join('/')
    const platform = getUrlParam('platform') || '0'
    const url = `${prefixUrl}/guide/${gameId}?access-token=${getStore(TOKEN)}&platform=${platform}`

    interface IResult {
      title: string;
      guides: IData[];
    }
    
    handleLoading(true)
    axios.get(url).then((response: { data: IResponseData<IResult> }) => {
      const res = response.data
      if (res.code === 200) {
        this.setState({ data: res.data.guides, prefixUrl })
      } else {
        handleError(res)
      }
      handleLoading(false)
    }).finally(() => {
      handleLoading(false)
      handleError()
    })
  }

  // 获取活动链接内的id
  handleGetGameId(): string {
    const activity_url = getStore(GUIDE_URL)
    const arr = activity_url.split('/')
    const gameId = arr.length > 0 ? arr[arr.length - 1] : ''

    return gameId
  }

  // 点击
  handleClick(id: string) {
    const { prefixUrl } = this.state
    const url = `${prefixUrl}/guide/article/${id}?access-token=${getStore(TOKEN)}`
    window.open(url)
  }
  
  render() {
    const { data } = this.state

    return (
      <>
        {
          data && data.map(item => (
            <div key={item.id} className={styles.item}>
              <span className={styles.title}>{ item.title }</span>
              <div
                className={styles.btn}
                onClick={() => this.handleClick(item.id)}
              >
                详情
              </div>
            </div>
          ))
        }
      </>
    )
  }
}
