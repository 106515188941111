import { Color } from '@material-ui/lab'
import { Component } from 'react'
import { danger, GAME_SID, GAME_SID_NAME, RED_BAG_ACTIVE, ROLE_ID, ROLE_NAME, success, USERNAME } from 'src/utils/config'
import { getLocalStorage, getStore } from 'src/utils/store'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import API from '../../../services/api'
import styles from './FloatmodRedBag.module.css'
import { dateDiff } from 'src/utils/utils';

type IProps = {
  handleLoading: (isLoading: boolean) => void;
  handleError: (response?: any) => void;
  handleMessage: (content: string, type?: Color) => void;
}

type IItems = {
  red_bag_id: string;
  show: string;
  hint: string;
  status: number; // 0未达成,1可领取,2已领取
}

type IState = {
  data: {
    balance: string;
    cash: string;
    endTime: string;
    desc: string;
  };
  radioValue: string;
  isWithdraw: boolean; // 提现
  items: IItems[];
  withdrawList: { label: string, value: string }[]
}

class Floatmod_Red_Bag extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.handleGetRedBag = this.handleGetRedBag.bind(this)
    this.handleClickRedBag = this.handleClickRedBag.bind(this)
    this.handleSwitchWithdraw = this.handleSwitchWithdraw.bind(this)
    this.handleChangeRadio = this.handleChangeRadio.bind(this)
    this.handleGetWithdraw = this.handleGetWithdraw.bind(this)
    this.handleWithdraw = this.handleWithdraw.bind(this)
  }

  state: IState = {
    data: {
      balance: '',
      cash: '',
      endTime: '',
      desc: ''
    },
    isWithdraw: false,
    radioValue: '',
    items: [],
    withdrawList: []
  }

  componentDidMount() {
    this.handleGetRedBag()
  }

  // 获取红包列表
  handleGetRedBag() {
    const { handleLoading, handleError } = this.props
    const uniq = getStore(RED_BAG_ACTIVE)
    const params = {
      uniq,
      role_id: getStore(ROLE_ID),
      server_id: getStore(GAME_SID),
      server_name: getStore(GAME_SID_NAME),
    }
    handleLoading(true)
    API.red_bag(params).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        const items: any[] = []
        const { data } = res
        for (let key in data.items) {
          items.push({
            red_bag_id: key,
            show: data.items[key].show,
            hint: data.items[key].hint,
            status: data.items[key].status,
          })
        }
        
        this.setState({ data, items })
      } else {
        handleError(response)
      }
      handleLoading(false)
    }).catch(() => {
      handleLoading(false)
      handleError()
    })
  }

  // 点击提现
  handleSwitchWithdraw() {
    const { isWithdraw } = this.state
    this.setState({ isWithdraw: !isWithdraw })

    if (!isWithdraw) {
      this.handleGetWithdraw()
    } else {
      this.handleGetRedBag()
    }
  }

  // 获取提现选项
  handleGetWithdraw() {
    const { handleLoading, handleError } = this.props
    const uniq = getStore(RED_BAG_ACTIVE)
    handleLoading(true)
    API.find_red_bag_cash({ uniq }).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        const withdrawList: { label: string, value: string }[] = []
        const { data } = res
        for(let key in data) {
          withdrawList.push({ label: data[key], value: key })
        }
        
        this.setState({ withdrawList })
      } else {
        handleError(res)
      }
      handleLoading(false)
    }).catch(() => {
      handleError()
      handleLoading(false)
    })
  }
  

  // 点击领取红包
  handleClickRedBag(red_bag_id: string) {
    const { handleLoading, handleError, handleMessage } = this.props
    const uniq = getStore(RED_BAG_ACTIVE)
    const params = {
      uniq,
      role_id: getStore(ROLE_ID),
      role_name: getStore(ROLE_NAME),
      server_id: getStore(GAME_SID),
      server_name: getStore(GAME_SID_NAME),
      username: getLocalStorage(USERNAME),
      red_bag_id
    }
    handleLoading(true)
    API.red_bag_issue(params).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        handleMessage(res.message || '领取成功', 'success')
        this.handleGetRedBag()
      } else {
        handleError(res)
      }
      handleLoading(false)
    }).catch(() => {
      handleError()
      handleLoading(false)
    })
  }

  // 选中单选
  handleChangeRadio(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ radioValue: (event.target as HTMLInputElement).value })
  }

  // 提现
  handleWithdraw() {
    const { radioValue } = this.state
    const { handleLoading, handleError, handleMessage } = this.props
    if (!radioValue) return handleMessage('请选择!')
    const uniq = getStore(RED_BAG_ACTIVE)
    const params = {
      uniq,
      role_id: getStore(ROLE_ID),
      server_id: getStore(GAME_SID),
      server_name: getStore(GAME_SID_NAME),
      money: parseFloat(radioValue)
    }
    
    handleLoading(true)
    API.red_bag_cash(params).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        handleMessage(res.message || '提现成功', 'success')
        this.handleGetWithdraw()
      } else {
        handleError(res)
      }
      handleLoading(false)
    }).catch(() => {
      handleError()
      handleLoading(false)
    })
  }

  render() {
    const { data, items, isWithdraw, withdrawList, radioValue } = this.state

    return (
      <div className={styles.box}>
        {
          !isWithdraw &&
          <>
            {
              data.endTime &&
              <>
                <div className={styles.title}>欢迎您,{ getLocalStorage(USERNAME) }</div>
                <div className={styles.item}>
                  <div className={styles.left}>
                    <div className={styles.row}>
                      <span className={styles.label}>我的钱包:</span>
                      <span className={styles.value}><span style={{ color: success }}>{ data.balance || 0 }</span>元</span>
                    </div>
                    <div className={styles.row}>
                      <span className={styles.label}>活动剩余时间:</span>
                      <span className={styles.value}>{ dateDiff(data.endTime) }</span>
                    </div>
                    <span style={{ color: danger }}>
                      { data.desc ? `提示：${data.desc}` : '' }
                    </span>
                  </div>
    
                  <div className={styles.right}>
                    <div className={styles.btn} onClick={this.handleSwitchWithdraw}>
                      去提现
                    </div>
                  </div>
                </div>
                {
                  items?.length > 0 && items.map(item => (
                    <div className={styles.item}>
                      <div className={styles.left}>
                        <span className={styles.row} style={{ color: success }}>{ item.show }</span>
                        <span>{ item.hint }</span>
                      </div>
                      <div className={styles.right}>
                        <div className={styles.btn}>
                          {
                            item.status === 0 &&
                            <div className={`${styles.btn} ${styles.btn_none}`}>未达成</div>
                          }
                          {
                            item.status === 1 &&
                            <div className={`${styles.btn}`} onClick={() => this.handleClickRedBag(item.red_bag_id)}>可领取</div>
                          }
                          {
                            item.status === 2 &&
                            <div className={`${styles.btn} ${styles.btn_none}`}>已领取</div>
                          }
                        </div>
                      </div>
                    </div>
                  ))
                }
              </>
            }

            {
              !data.endTime &&
              !getStore(GAME_SID) &&
              <div className={styles.none}>请选择区服并进入游戏后访问！</div>
            }
            {
              !data.endTime &&
              getStore(GAME_SID) &&
              <div className={styles.none}>暂无红包,敬请期待...</div>
            }
          </>
        }

        {
          isWithdraw &&
          <div className={styles.withdraw_box}>
            <div className={styles.title_box}>
              <div className={styles.left}>
                <div className={styles.title}>欢迎您,{ getLocalStorage(USERNAME) }</div>
                <div className={styles.sub_title}>
                  <span className={styles.label}>我的钱包:</span>
                  <span className={styles.label}><span style={{ color: 'rgb(189, 151, 108)' }}>{ data.balance || 0 }</span>元</span>
                  <span className={styles.label}>已提现金额:</span>
                  <span className={styles.label}><span style={{ color: 'rgb(189, 151, 108)' }}>{ data.cash || 0 }</span>元</span>
                </div>
              </div>
              
              <div className={styles.right}>
                <div className={styles.btn} onClick={this.handleSwitchWithdraw}>
                  抢红包
                </div>
              </div>
            </div>

            <div className={styles.withdraw_list_box}>
              <RadioGroup row aria-label="gender" name="gender1" value={radioValue} onChange={this.handleChangeRadio}>
                {
                  withdrawList?.length > 0 && withdrawList.map(item => (
                    <FormControlLabel value={item.value} control={<Radio style={{ color: '#fff' }} />} label={item.label} />
                  ))
                }
              </RadioGroup>
              <div className={`${styles.btn} ${styles.withdraw_btn}`} onClick={this.handleWithdraw}>
                提现
              </div>
            </div>

            <div className={styles.prompt}>
              温馨提示：<span className={styles.warn}>请在红包领取活动结束7天内申请提现，逾期将无法提现！</span>我们将在7个工作日内审核您的申请，审核无异样后，将您提取 的红包发放到您的绑定手机的支付宝中，在提取之前您必须进行实名认证及 绑定手机，所绑定的手机必须开通支付宝账户。每次提取的金额最低500元， 不超过2000元。（最终解释权归运营方所有）
            </div>
          </div>
        }
      </div>
    )
  }
}

export default Floatmod_Red_Bag
