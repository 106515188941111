import Cookies from 'js-cookie'

export function setStore(key: string, value: string) {
  return Cookies.set(key, value, { expires: 7 })
}

export function getStore(key: string) {
  return Cookies.get(key) || ''
}

export function removeStore(key: string) {
  return Cookies.remove(key)
}

export function setLocalStorage(key: string, value: string) {
  return localStorage.setItem(key, value)
}

export function getLocalStorage(key: string) {
  return localStorage.getItem(key) || ''
}

export function removeLocalStorage(key: string) {
  return localStorage.removeItem(key)
}
