import { Component } from 'react'
import { ACTIVITY_URL, GAME_ID, TOKEN } from 'src/utils/config'
import { getStore } from 'src/utils/store'
import styles from './FloatmodCustomerNav.module.css'

interface IProps {
}

interface IState {
  activityUrl: string;
}

export default class FloatmodActivity extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const activityUrl = getStore(ACTIVITY_URL)

    this.state = {
      activityUrl,
    }
  }

  render() {
    const { activityUrl } = this.state
    const gameId = getStore(GAME_ID)
    const url = `${activityUrl}?access-token=${getStore(TOKEN)}&game_id=${gameId}`

    return (
      <div className={styles.flex_center_box}>
        <iframe
          src={url}
          title="活动"
          frameBorder={0}
          allowTransparency={true}
          width="100%"
          height="100%"
          style={{ width: '100%', height: '100%', minHeight: 250 }}
        ></iframe>
      </div>
    )
  }
}
