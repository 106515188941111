import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IProps {
  handleLogout: () => void;
}

function NotAdult(props: IProps) {
  const { handleLogout } = props
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    handleLogout()
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold' }}>
          健康上网提醒
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            根据国家对未成年人防沉迷健康上网的规定，禁止未成年人玩当前这款游戏，请您退出游戏，好好休息，感谢您的理解！
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>退出游戏</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default NotAdult