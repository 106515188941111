import { Component } from 'react'
import { getLocalStorage, getStore, setLocalStorage, setStore } from '../../utils/store'
import { handleGesture, handleGetGameId } from '../../utils/utils'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styles from './index.module.css'
import MuiAlert, { Color } from '@material-ui/lab/Alert'
import Loading from '../../components/Loading'
import API from '../../services/api'
import { CODE, GAME_ID, MESSAGE_TIME } from 'src/utils/config'
import Snackbar from '@material-ui/core/Snackbar'

type IState = {
  isAlert: boolean;
  isLoading: boolean;
  isPassword: boolean;
  isPasswordRepeat: boolean;
  isSendVerify: boolean;
  alertContent: string;
  username: string;
  verifyCode: string;
  password: string;
  passwordRepeat: string,
  alertType: Color;
  phone: string;
  sendVerifyContent: string;
  type: 'phone' | 'password';
  time: any;
}

class Forget extends Component<RouteComponentProps, IState> {
  constructor(props: RouteComponentProps) {
    super(props)

    this.handleCountdown = this.handleCountdown.bind(this)
    this.handleGetVerfity = this.handleGetVerfity.bind(this)
    this.handleSumbit = this.handleSumbit.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleVerifyCode = this.handleVerifyCode.bind(this)
  }

  state: IState = {
    isAlert: false,
    isLoading: false,
    isPassword: false,
    isPasswordRepeat: false,
    isSendVerify: false,
    username: '',
    verifyCode: '',
    password: '',
    passwordRepeat: '',
    alertType: 'warning',
    type: 'phone',
    phone: '',
    alertContent: '',
    sendVerifyContent: '',
    time: null
  }

  componentDidMount() {
    document.title = `${process.env.REACT_APP_COMPANY_NAME}-忘记密码`
    this.handleFirstEntry()

    // IOS禁止缩放
    handleGesture()
  }

  // 首次进入
  handleFirstEntry() {
    let gameId = getStore(GAME_ID)
    if (!gameId) {
      gameId = handleGetGameId(this.props)
      setStore(GAME_ID, gameId)
      if(!gameId) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '未获取到游戏ID，请重试!' })
    }
    if (gameId) {
      setStore(GAME_ID, gameId)
      this.handleVerifyCode()
    }
  }
  // 判断是否存在code字段
  handleVerifyCode() {
    const code = getLocalStorage(CODE)
    if (!code) {
      this.setState({ isLoading: true })
      return API.active().then((response: any) => {
        const res = response.data
        this.setState({ isLoading: false })
        if (res?.code === 200) {
          const { code } = res.data
          setLocalStorage(CODE, code)
        }
      }).catch(() => {
        this.setState({ isLoading: false })
      })
    }
  }
  
  // 获取验证码
  handleGetVerfity() {
    const { username } = this.state
    let gameId = getStore(GAME_ID)
    if (!gameId) {
      gameId = handleGetGameId(this.props)
      setStore(GAME_ID, gameId)
      if(!gameId) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '未获取到游戏ID，请重试!' })
    }
    if (!username) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '请输入用户名(账号)!' })

    const params = {
      type: 'username',
      username
    }
    this.setState({ isLoading: true })
    API.phone_forget(params).then((response: any) => {
      const res = response.data
      this.setState({ isLoading: false })
      if (res?.code === 200) {
        const { phone } = res.data
        const newPhone: string = phone.substr(0, 3) + "****" + phone.substr(7)
        this.setState({ isAlert: true, alertType: 'success', alertContent: '发送成功', type: 'password', phone: newPhone })
        this.handleCountdown()
      }
    }).catch(() => {
      this.setState({ isLoading: false })
    })
  }

  // 倒计时60秒
  handleCountdown(date = 60000) {
    let num = date / 1000
    this.setState({
      isSendVerify: true,
      sendVerifyContent: `等待${num}s`,
      time: setInterval(() => {
        num--
        this.setState({ sendVerifyContent: `等待${num}s` })
        if (num === 0) {
          clearInterval(this.state.time)
          this.setState({ isSendVerify: false })
        }
      }, 1000)
    })
  }

  // 点击返回
  handleGoBack() {
    const { search } = this.props.location
    this.props.history.push(`/index${search}`)
  }

  // 提交
  handleSumbit() {
    const { type } = this.state
    if (type === 'phone') {
      this.handleGetVerfity()
    } else {
      this.handleFinally()
    }
  }
  
  // 提交完成
  handleFinally() {
    const { username, verifyCode, password, passwordRepeat } = this.state
    const params = {
      verify_code: verifyCode,
      new_password: password,
      new_password_repeat: passwordRepeat,
      username,
      type: 'password'
    }
    this.setState({ isLoading: true })
    API.phone_forget(params).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        const { search } = this.props.location
        this.props.history.push(`/index${search}`)
      }
      this.setState({ isLoading: false })
    }).catch(() => {
      this.setState({ isLoading: false })
    })
  }

  render() {
    const {
      isAlert,
      isLoading,
      isPassword,
      isPasswordRepeat,
      isSendVerify,
      username,
      verifyCode,
      password,
      passwordRepeat,
      phone,
      type,
      alertType,
      alertContent,
      sendVerifyContent,
    } = this.state

    return (
      <div className={styles.body}>
        <div className={styles.box}>
          <div className={styles.title}>
            <span>手机找回密码</span>
            <div className={styles.go_back} onClick={this.handleGoBack}>
              <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
              </svg>
            </div>
          </div>
          <div className={styles.nav}>
            <div className={`${styles.nav_item} ${styles.active}`}>确认账户<i className={styles.icon_arrow}></i></div>
            <div className={`${styles.nav_item} ${type === 'password' ? styles.active : ''}`}>设置新密码<i className={styles.icon_arrow}></i></div>
          </div>
          <div className={styles.content}>
            {
              type === 'phone' &&
              <div className={styles.row}>
                <input
                  className={styles.input}
                  type="text"
                  placeholder="请输入用户名(账号)"
                  value={username}
                  onChange={e => this.setState({ username: e.target.value })}
                />
                <div className={styles.phone_close} onClick={() => this.setState({ username: '' })}>
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                  </svg>
                </div>
              </div>
            }
            {
              type === 'password' &&
              <div>
                <div className={styles.phone_verfity}>
                  <span>已经给</span>
                  <span>{ phone }</span>
                  <span>号码发送一条验证信息</span>
                </div>
                <div className={`${styles.row} ${styles.phone_row}`}>
                  <input
                    className={styles.input}
                    placeholder="请输入验证码"
                    value={verifyCode}
                    onChange={e => this.setState({ verifyCode: e.target.value })}
                  />
                  
                  { 
                    !isSendVerify &&
                    <div className={styles.send_verify} onClick={this.handleGetVerfity}>发送验证码</div>
                  }
                  { 
                    isSendVerify &&
                    <div className={styles.time}>{ sendVerifyContent }</div>
                  }
                </div>
                <div className={`${styles.row} ${styles.phone_row}`}>
                  <input
                    className={styles.input}
                    type={ isPassword ? "text" :"password" }
                    placeholder="请输入新密码"
                    value={password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                  <svg viewBox="0 0 100 100" className={styles.icon} onClick={() => this.setState({ isPassword: !isPassword })}>
                    <path stroke="#f88e24" fill="none" stroke-linecap="round" stroke-width="7" d="M10,50 Q50,10 90,50"></path>
                    <circle fill="none" stroke="#f88e24" stroke-width="7" cx="50" cy="50" r="15" stroke-linecap="round" transform="rotate(90 50 50)"></circle>
                    <path stroke="#f88e24" fill="none" stroke-linecap="round" stroke-width="7" d="M10,50 Q50,90 90,50"></path>
                    {
                      !isPassword &&
                      <line x1="80" y1="20" x2="20" y2="80" stroke="#f88e24" stroke-width="7" />
                    }
                  </svg>
                </div>
                <div className={`${styles.row} ${styles.phone_row}`}>
                  <input
                    className={styles.input}
                    type={ isPasswordRepeat ? "text" :"password" }
                    placeholder="请再次输入密码"
                    value={passwordRepeat}
                    onChange={e => this.setState({ passwordRepeat: e.target.value })}
                  />
                  <svg viewBox="0 0 100 100" className={styles.icon}  onClick={() => this.setState({ isPasswordRepeat: !isPasswordRepeat })}>
                    <path stroke="#f88e24" fill="none" stroke-linecap="round" stroke-width="7" d="M10,50 Q50,10 90,50"></path>
                    <circle fill="none" stroke="#f88e24" stroke-width="7" cx="50" cy="50" r="15" stroke-linecap="round" transform="rotate(90 50 50)"></circle>
                    <path stroke="#f88e24" fill="none" stroke-linecap="round" stroke-width="7" d="M10,50 Q50,90 90,50"></path>
                    {
                      !isPasswordRepeat &&
                      <line x1="80" y1="20" x2="20" y2="80" stroke="#f88e24" stroke-width="7" />
                    }
                  </svg>
                </div>
              </div>
            }
            <div className={styles.row}>
              <button className={styles.btn} onClick={this.handleSumbit}>提交</button>
            </div>
            
            {
              type === 'password' &&
              <div className={styles.no_verfity}>收不到验证码?请联系客服!</div>
            }
          </div>
        </div>

        <Snackbar
          open={isAlert}
          autoHideDuration={MESSAGE_TIME}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => this.setState({ isAlert: false })}
        >
          <MuiAlert
            elevation={6} variant="filled"
            onClose={() => this.setState({ isAlert: false })}
            severity={alertType}
          >
            { alertContent }
          </MuiAlert>
        </Snackbar>
        <Loading isLoading={isLoading} />
      </div>

    )
  }
}

export default withRouter(Forget)
