import { getLocalStorage, getStore, setLocalStorage, setStore } from './store'
import API from '../services/api'
import { ACTIVITY_URL, CHECK_BIND_POPUP, CODE, EXCHANGE_GROUP, GAME_ID, GM_QQ, GUIDE_URL, IS_ACTIVITY_URL, NAME_VERIFY, RED_BAG_ACTIVE, TOKEN } from './config'
import axios from 'axios'

// 判断是否存在code字段
export function handleVerifyCode() {
  const code = getLocalStorage(CODE)
  if (!code) {
    return API.active().then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        const { code } = res.data
        setLocalStorage(CODE, code)
      }
    }).catch(() => {
    })
  }
}

// 获取活动链接内的id
function handleActivityId(activity_url: string): string {
  const arr = activity_url.split('/')
  const id = arr.length > 0 ? arr[arr.length - 1] : ''

  return id
}

interface IActivityResult {
  display: boolean;
}

// 判断是否显示活动页
export function handleIsActivityUrl(): void {
  const activity_url = getStore(ACTIVITY_URL)
  if (activity_url) {
    const id = handleActivityId(activity_url)

    let prefixArr = (process.env.REACT_APP_BASE_API as string).split('/')
    prefixArr.length = prefixArr?.length - 1
    const prefixUrl = prefixArr.join('/')
    const gameId = getStore(GAME_ID)
    const url = `${prefixUrl}/activity/check/${id}?access-token=${getStore(TOKEN)}&game_id=${gameId}`
    axios.get(url).then((response: { data: IResponseData<IActivityResult> }) => {
      const res = response.data
      if (res.code === 200) {
        const { display } = res.data
        setStore(IS_ACTIVITY_URL, display ? 'true' : 'false')
      }
    })
  }
}

// 获取初始化配置
export function handleInitConfig(gameId: string, err: (res: { message: string }) => any, callback?: (data?: any) => void): void {
  API.sdk_config({ gameId }).then((response: any) => {
    const res = response.data
    if (res?.code === 200) {
      const {
        red_bag_active,
        exchange_group,
        name_verify,
        gm_qq,
        guide_url,
        activity_url,
        check_bind_popup
      } = res.data
      setStore(RED_BAG_ACTIVE, red_bag_active || '')
      setStore(EXCHANGE_GROUP, exchange_group || '')
      setStore(GUIDE_URL, guide_url || '')
      setStore(ACTIVITY_URL, activity_url || '')
      setStore(NAME_VERIFY, name_verify || 0)
      setStore(CHECK_BIND_POPUP, check_bind_popup)
      setStore(GM_QQ, gm_qq || '800183043')
      callback && callback(res.data)
    } else {
      err(res)
    }
  }).catch(() => err({ message: '服务器错误' }))
}
