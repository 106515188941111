import React, { Component } from 'react'
import { getLocalStorage, getStore } from '../../../utils/store'
import styles from './Floatmod.module.css'
import logoImg from '../../../assets/logo.png'
import userImg from '../../../assets/user.png'
import giftImg from '../../../assets/gift.png'
import phoneImg from '../../../assets/phone.png'
import appDownloadImg from '../../../assets/app_download.png'
import passwordImg from '../../../assets/password.png'
import mobileImg from '../../../assets/mobile.png'
import realnameImg from '../../../assets/realname.png'
import redBagImg from '../../../assets/red_bag.png'
import activityImg from '../../../assets/activity.png'
import guideImg from '../../../assets/guide.png'
import { getUrlParam, handleGesture, handleIsPC } from 'src/utils/utils'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ACTIVITY_URL, BIND_PHONE, EXCHANGE_GROUP, GAME_SID, GUIDE_URL, IDENTITY, IS_ACTIVITY_URL, MESSAGE_TIME, RED_BAG_ACTIVE, USERNAME } from 'src/utils/config'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { Color } from '@material-ui/lab/Alert'
import API from '../../../services/api'
import Loading from '../../../components/Loading'
import Draggable from 'react-draggable';
import FloatmodRedBag from './FloatmodRedBag'
import FloatmodCustomerNav from './FloatmodCustomerNav'
import FloatmodGuide from './FloatmodGuide'
import FloatmodActivity from './FloatmodActivity'
import FloatmodRealname from './FloatmodRealname'
import FloatmodBindPhone from './FloatmodBindPhone'

type IType = 'user' | 'gift' | 'phone' | 'red_bag' | 'guide_url' | 'activity_url';
export type IUserType = 'password' | 'bind_phone' | 'realname';
type IState = {
  isLoading: boolean;
  isExtend: boolean;
  isDialog: boolean;
  isGoBack: boolean;
  isUserDialog: boolean;
  isAlert: boolean;
  isDrag: boolean;
  dragHelf: 'left' | 'right';
  left: number;
  top: number;
  clientX: number; // 记录开始拖拽位置
  clientY: number; // 记录开始拖拽位置
  opacity: number;
  old_password: string;
  new_password: string;
  new_password_repeat: string;
  phone: string;
  type: IType;
  userType: IUserType;
  alertType: Color;
  alertContent: string;
  giftList: any;
}

type IProps = {
  handleVerifyCode: () => void;
  handleLogout: () => void;
  handleCloseRealname: (isDialog: boolean) => void;
  handleCloseBindPhone: (isDialog: boolean) => void;
} & RouteComponentProps

class Floatmod extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isLoading: false,
      isExtend: false,
      isDialog: false,
      isGoBack: false,
      isUserDialog: false,
      isAlert: false,
      isDrag: false,
      left: document.body.clientWidth - 35,
      top: 80,
      clientX: document.body.clientWidth - 35,
      clientY: 80,
      opacity: .5,
      phone: '',
      dragHelf: 'right',
      alertType: 'warning',
      alertContent: '',
      old_password: '',
      new_password: '',
      new_password_repeat: '',
      type: 'user',
      userType: 'password',
      giftList: [],
    }

    this.handleClickType = this.handleClickType.bind(this)
    this.handleClickUserType = this.handleClickUserType.bind(this)
    this.templateUserNav = this.templateUserNav.bind(this)
    this.templateGiftEmptyNav = this.templateGiftEmptyNav.bind(this)
    this.tempalteGiftDataNaV = this.tempalteGiftDataNaV.bind(this)
    this.templateCustomerNav = this.templateCustomerNav.bind(this)
    this.templateGuideUrlNav = this.templateGuideUrlNav.bind(this)
    this.templateActivityUrlNav = this.templateActivityUrlNav.bind(this)
    this.templatePasswrod = this.templatePasswrod.bind(this)
    this.templatBindPhone = this.templatBindPhone.bind(this)
    this.templatRealname = this.templatRealname.bind(this)
    this.handleSumbitPassword = this.handleSumbitPassword.bind(this)
    this.handleError = this.handleError.bind(this)
    this.handleGetGift = this.handleGetGift.bind(this)
    this.handleClickGift = this.handleClickGift.bind(this)
    this.handleDrag = this.handleDrag.bind(this)
    this.handleDragStart = this.handleDragStart.bind(this)
    this.handleDragStop = this.handleDragStop.bind(this)
    this.handleLoading = this.handleLoading.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.handleUserDialog = this.handleUserDialog.bind(this)
    this.handleWidth = this.handleWidth.bind(this)
  }

  componentDidMount() {
    // IOS禁止缩放
    handleGesture()
  }

  // 错误处理
  handleError(response?: any) {
    return this.setState({ isAlert: true, alertType: 'error', alertContent: response?.message || '服务器错误!' })
  }

  // 点击类型
  handleClickType(e: any, type: IType) {
    e.stopPropagation()
    this.setState({ isDialog: true, type })

    // 选择礼包调取接口
    if (type === 'gift') {
      this.handleGetGift()
    }
  }

  // 点击账户类型
  handleClickUserType(userType: IUserType) {
    this.setState({ isUserDialog: true, userType })
  }

  // 账户导航内容
  templateUserNav() {
    return (
      <>
        <div className={styles.float_row} id="user_info" onClick={() => this.setState({ isGoBack: true })}>
          <div className={styles.float_left}>
            <img src={userImg} alt='userImg' />
            <span className={styles.float_label}>账户信息</span>
            <span>{getLocalStorage(USERNAME) || ''}</span>
          </div>
          <div className={styles.float_right}>
            <span>账号切换</span>
            <i className="icon_arrow"></i>
          </div>
        </div>
        <div className={styles.float_row} onClick={() => this.handleClickUserType('password')}>
          <div className={styles.float_left}>
            <img src={passwordImg} alt='' />
            <span className={styles.float_label}>修改密码</span>
          </div>
          <div className={styles.float_right}>
            <i className="icon_arrow"></i>
          </div>
        </div>
        <div className={styles.float_row} onClick={() => getStore(BIND_PHONE) !== 'true' && this.handleClickUserType('bind_phone')}>
          <div className={styles.float_left}>
            <img src={mobileImg} alt='' />
            <span className={styles.float_label}>绑定手机</span>
          </div>
          <div className={styles.float_right}>
            <span>{ getStore(BIND_PHONE) === 'true' ? '已绑定' : '未绑定' }</span>
            <i className="icon_arrow"></i>
          </div>
        </div>
        <div className={styles.float_row} onClick={() => getStore(IDENTITY) !== 'true' && this.handleClickUserType('realname')}>
          <div className={styles.float_left}>
            <img src={realnameImg} alt='' />
            <span className={styles.float_label}>实名认证</span>
          </div>
          <div className={styles.float_right}>
            <span>{ getStore(IDENTITY) === 'true' ? '已认证' : '未认证' }</span>
            <i className="icon_arrow"></i>
          </div>
        </div>
      </>
    )
  }

  // 获取礼包数据
  handleGetGift() {
    this.setState({ isLoading: true })
    API.gift_bag_list().then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        this.setState({ giftList: res.data })
      } else {
        this.handleError(res)
      }
      this.setState({ isLoading: false })
    }).catch(() => {
      this.setState({ isLoading: false })
    })
  }

  // 添加领取礼包事件
  handleClickGift(id: string) {
    const newGiftList = this.state.giftList
    this.setState({ isLoading: true })
    const params = { id, game_sid: getStore(GAME_SID) || '' }
    API.gift_bag_finish(params).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        const { sn } = res.data
        newGiftList.forEach((item: any) => {
          if (item.id === id) {
            item.sn = sn
            item.is_use = true
          }
        })
        this.setState({ giftList: newGiftList })
      } else {
        this.handleError(res)
      }
      this.setState({ isLoading: false })
    }).catch(() => {
      this.handleError()
      this.setState({ isLoading: false })
    })

  }

  // 礼包无数据内容
  templateGiftEmptyNav() {
    return (
      <div className={styles.float_gift_content}>
        暂无礼包,敬请期待...
      </div>
    )
  }

  // 礼包有数据内容
  tempalteGiftDataNaV() {
    const { giftList } = this.state
    return (
      <>
        {
          giftList.map((item: any, index: number) => (
            <div className={`${styles.float_row} ${styles.gift_row}`} key={index}>
              <div className={styles.float_left}>
                <span className={styles.green}>{ item.name }</span>
                <span>剩余<span className={`${styles.green} ${styles.gift_value}`}>{ item.nums }</span></span>
                <span>{ item.content }</span>
                <span>礼包码:<span className={`${styles.red} ${styles.gift_value}`}>{ item.is_use ? item.sn : '暂未领取' }</span></span>
              </div>
              <div className={styles.float_right}>
                <button
                  className={`${styles.btn} ${ item.is_use ? styles.active : styles.gift_get_btn }`}
                  onClick={() => !item.is_use && this.handleClickGift(item.id)}
                >
                  { item.is_use ? '已领取' : '领取' }
                </button>
              </div>
            </div>
          ))
        }
      </>
    )
  }
    
  // 客服导航内容
  templateCustomerNav() {
    const exchange_group: string[] | false = getStore(EXCHANGE_GROUP) ? JSON.parse(getStore(EXCHANGE_GROUP)) : false
  
    return (
      <FloatmodCustomerNav exchange_group={exchange_group || []} />
    )
  }

  // 攻略内容
  templateGuideUrlNav() {
    return (
      <FloatmodGuide
        handleError={this.handleError}
        handleLoading={this.handleLoading}
      />
    )
  }

  // 活动内容
  templateActivityUrlNav() {
    return (
      <FloatmodActivity />
    )
  }

  // 修改密码内容
  templatePasswrod() {
    const { old_password, new_password, new_password_repeat } = this.state
    return (
      <>
        <div className={styles.user_row}>
          <input
            className={styles.input}
            value={old_password}
            type="password"
            placeholder="请输入旧密码"
            onChange={e => this.setState({ old_password: e.target.value })}
          />
          <div className={styles.close} onClick={() => this.setState({ old_password: '' })}>
            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
            </svg>
          </div>
        </div>
        <div className={styles.user_row}>
          <input
            className={styles.input}
            value={new_password}
            type="password"
            placeholder="请输入新密码"
            onChange={e => this.setState({ new_password: e.target.value })}
          />
          <div className={styles.close} onClick={() => this.setState({ new_password: '' })}>
            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
            </svg>
          </div>
        </div>
        <div className={styles.user_row}>
          <input
            className={styles.input}
            value={new_password_repeat}
            type="password"
            placeholder="请确认新密码"
            onChange={e => this.setState({ new_password_repeat: e.target.value })}
          />
          <div className={styles.close} onClick={() => this.setState({ new_password_repeat: '' })}>
            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
            </svg>
          </div>
        </div>
        <div className={styles.user_btn_row}>
          <button className={styles.btn} onClick={this.handleSumbitPassword}>提交</button>
        </div>
        <div className={styles.user_btn_row} onClick={() => this.setState({ isUserDialog: false })}>
          <span id="user_goback">放弃修改</span>
        </div>
      </>
    )
  }

  // 提交修改密码
  handleSumbitPassword() {
    const { old_password, new_password, new_password_repeat } = this.state
    if (!old_password) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '请输入旧密码' })
    if (!new_password) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '请输入新密码' })
    if (!new_password_repeat) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '请输入重复密码' })

    const params = { old_password, new_password, new_password_repeat }
    this.setState({ isLoading: true })
    API.modify_password(params).then((response: any) => {
      const res = response.data
      if (res && res.code === 200) {
        this.setState({
          isAlert: true,
          alertType: 'success',
          alertContent: res.message || '成功!',
          isUserDialog: false
        })
      } else {
        this.handleError(res)
      }

      this.setState({ isLoading: false })
    }).catch(() => {
      this.handleError()
      this.setState({ isLoading: false })
    })
  }
    
  // 绑定手机内容
  templatBindPhone() {
    const { handleCloseBindPhone } = this.props
    
    return (
      <>
        <FloatmodBindPhone
          handleError={this.handleError}
          handleLoading={this.handleLoading}
          handleMessage={this.handleMessage}
          handleDialog={this.handleUserDialog}
          handleCloseBindPhone={handleCloseBindPhone}
        />
      </>
    )
  }

  // 实名认证内容
  templatRealname() {
    const { handleCloseRealname } = this.props

    return (
      <FloatmodRealname
        handleError={this.handleError}
        handleLoading={this.handleLoading}
        handleMessage={this.handleMessage}
        handleDialog={this.handleUserDialog}
        handleCloseRealname={handleCloseRealname}
      />
    )
  }

  // 拖拽开始
  handleDragStart(e: any) {
    e.preventDefault()
    let clientX = 0
    let clientY = 0
    const { left, top } = this.state
    if (handleIsPC()) {
      clientX = e.clientX - 30 || left
      clientY = e.clientY - 30 || top
    } else {
      clientX = e?.targetTouches?.[0]?.clientX - 30 || left
      clientY = e?.targetTouches?.[0]?.clientY - 30 || top
    }
    this.setState({ opacity: 1, left: clientX, top: clientY, clientX, clientY })
  }

  // 拖拽
  handleDrag(e: any) {
    let clientX = 0
    let clientY = 0
    const { left, top } = this.state
    const { clientWidth, clientHeight } = document.body
    if (handleIsPC()) {
      clientX = e.clientX - 30 || left
      clientY = e.clientY - 30 || top
    } else {
      clientX = e?.targetTouches?.[0]?.clientX - 30 || left
      clientY = e?.targetTouches?.[0]?.clientY - 30 || top
    }
    if (clientX < 0) clientX = 0
    if (clientX > clientWidth - 60) clientX = clientWidth - 60
    if (clientY < 0) clientY = 0
    if (clientY > clientHeight - 60) clientY = clientHeight - 60

    this.setState({
      dragHelf: clientWidth / 2 > clientX ? 'left' : 'right',
      left: clientX,
      top: clientY,
      isDrag: true,
    })
  }

  // 拖拽结束
  handleDragStop(e: any) {
    const { left, top, clientX, clientY, dragHelf, isExtend } = this.state
    const { clientWidth } = document.body

    if (left === clientX && top === clientY) {
      this.setState({ isExtend: !isExtend, clientX: left, clientY: top })
    }
    this.setState({ left: dragHelf === 'left' ? 0 : clientWidth - 70, isDrag: false })


    setTimeout(() => {
      const newIsExtend = this.state.isExtend
      const newIsDrag= this.state.isDrag
      !newIsDrag && !newIsExtend && this.setState({
        left: dragHelf === 'left' ? -35 : clientWidth - 35, opacity: .5
      })
    }, 1000);
  }

  // loading
  handleLoading(isLoading: boolean) {
    this.setState({ isLoading })
  }

  // 处理弹窗
  handleUserDialog(isShow: boolean) {
    this.setState({ isUserDialog: isShow })
  }

  // 提示
  handleMessage(content: string, type: Color = 'warning') {
    this.setState({ isAlert: true, alertType: type, alertContent: content })
  }

  // 计算浮窗宽度
  handleWidth() {
    let length = 3

    if (getStore(GAME_SID) && getStore(RED_BAG_ACTIVE)) length++
    if (getStore(GUIDE_URL)) length++
    if (getStore(ACTIVITY_URL) && getStore(IS_ACTIVITY_URL) === 'true') length++
  
    return length * (length === 3 ? 85 : 65)
  }

  render() {
    const {
      isLoading,
      isExtend,
      isDialog,
      isUserDialog,
      isGoBack,
      type,
      userType,
      isAlert,
      alertType,
      alertContent,
      giftList,
      left,
      top,
      opacity,
      dragHelf
    } = this.state
    const { handleLogout } = this.props
    const platform = parseInt(getUrlParam('platform') || '0')
    const width = this.handleWidth()

    return (
      <>
        <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={{x: 85, y: 80}}
          position={{x: left, y: top }}
          grid={[5, 5]}
          scale={1}
          onStart={this.handleDragStart}
          onDrag={this.handleDrag}
          onStop={this.handleDragStop}
        >
          <div style={{ position: 'fixed', zIndex: 9999 }}>
            <div
              className={styles.floatmod}
              style={{ opacity }}
            >
              <img src={logoImg} alt='' className={`${styles.floaticon} handle`} />
              {
                isExtend &&
                <div
                  className={styles.float_content}
                  style={{
                    width,
                    left: dragHelf === 'right' ? -(width - 70) : 0,
                    padding: dragHelf === 'right' ? '0 65px 0 20px' : '0 20px 0 65px'
                  }}
                >
                  <div className={styles.float_item} onClick={e => this.handleClickType(e, 'user')}>
                    <img className={styles.img_item} src={userImg} alt='' />
                    <span className={styles.img_label}>账号</span>
                  </div>
                  <div className={styles.float_item} onClick={e => this.handleClickType(e, 'gift')}>
                    <img className={styles.img_item} src={giftImg} alt='' />
                    <span className={styles.img_label}>礼包</span>
                  </div>
                  <div className={styles.float_item} onClick={e => this.handleClickType(e, 'phone')}>
                    <img
                      className={styles.img_item}
                      src={ platform === 0 ? appDownloadImg : phoneImg}
                      alt=''
                    />
                    <span className={styles.img_label}>{ platform === 0 ? 'APP下载' : '客服' }</span>
                  </div>
                  {
                    getStore(GAME_SID) &&
                    getStore(RED_BAG_ACTIVE) &&
                    <div className={styles.float_item} onClick={e => this.handleClickType(e, 'red_bag')}>
                      <img className={styles.img_item} src={redBagImg} alt='' />
                      <span className={styles.img_label}>红包</span>
                    </div>
                  }
                  {
                    getStore(GUIDE_URL) &&
                    <div className={styles.float_item} onClick={e => this.handleClickType(e, 'guide_url')}>
                      <img className={styles.img_item} src={guideImg} alt='' />
                      <span className={styles.img_label}>攻略</span>
                    </div>
                  }
                  {
                    getStore(ACTIVITY_URL) &&
                    getStore(IS_ACTIVITY_URL) === 'true' &&
                    <div className={styles.float_item} onClick={e => this.handleClickType(e, 'activity_url')}>
                      <img className={styles.img_item} src={activityImg} alt='' />
                      <span className={styles.img_label}>活动</span>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </Draggable>

        {
          isDialog &&
          <div className={`${styles.dialog} ${styles.float_dialog}`}>
            <div className={styles.box}>
              <div className={styles.dialog_close} onClick={() => this.setState({ isDialog: false })}>
                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                </svg>
              </div>
              <div className={styles.title}>用户中心</div>
              <div className={styles.nav}>
                <div
                  className={`${styles.nav_item} ${type === 'user' ? styles.active : ''}`}
                  onClick={e => this.handleClickType(e, 'user')}
                >
                  账号
                </div>
                <div
                  className={`${styles.nav_item} ${type === 'gift' ? styles.active : ''}`}
                  onClick={e => this.handleClickType(e, 'gift')}
                >
                  礼包
                </div>
                <div
                  className={`${styles.nav_item} ${type === 'phone' ? styles.active : ''}`}
                  onClick={e => this.handleClickType(e, 'phone')}
                >
                  { platform === 0 ? 'APP下载' : '客服'}
                </div>
                {
                  getStore(GAME_SID) &&
                  getStore(RED_BAG_ACTIVE) &&
                  <div
                    className={`${styles.nav_item} ${type === 'red_bag' ? styles.active : ''}`}
                    onClick={e => this.handleClickType(e, 'red_bag')}
                  >
                    红包
                  </div>
                }
                {
                  getStore(GUIDE_URL) &&
                  <div
                    className={`${styles.nav_item} ${type === 'guide_url' ? styles.active : ''}`}
                    onClick={e => this.handleClickType(e, 'guide_url')}
                  >
                    攻略
                  </div>
                }
                {
                  getStore(ACTIVITY_URL) &&
                  getStore(IS_ACTIVITY_URL) === 'true' &&
                  <div
                    className={`${styles.nav_item} ${type === 'activity_url' ? styles.active : ''}`}
                    onClick={e => this.handleClickType(e, 'activity_url')}
                  >
                    活动
                  </div>
                }
              </div>
              <div id="float_nav_content" className={styles.float_nav_content}>
                {
                  type === 'user' &&
                  this.templateUserNav()
                }
                {
                  type === 'gift' &&
                  <>
                    {
                      giftList.length > 0 &&
                      this.tempalteGiftDataNaV()
                    }
                    {
                      giftList.length === 0 &&
                      this.templateGiftEmptyNav()
                    }
                  </>
                }
                {
                  type === 'phone' &&
                  this.templateCustomerNav()
                }
                {
                  type === 'red_bag' &&
                  getStore(GAME_SID) &&
                  getStore(RED_BAG_ACTIVE) &&
                  <FloatmodRedBag
                    handleMessage={this.handleMessage}
                    handleLoading={this.handleLoading}
                    handleError={this.handleError}
                  />
                }
                {
                  type === 'guide_url' &&
                  this.templateGuideUrlNav()
                }
                {
                  type === 'activity_url' &&
                  this.templateActivityUrlNav()
                }
              </div>
            </div>
          </div>
        }
        {       
          isUserDialog && userType === 'password' &&
          <div className={`${styles.dialog} ${styles.user_dialog}`}>
            <div className={styles.box}>
              <div className={styles.dialog_close}  onClick={() => this.setState({ isUserDialog: false })}>
                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                  <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                </svg>
              </div>
              <div className={styles.title}>
                {
                  userType === 'password' &&
                  <span>修改密码</span>
                }
              </div>
              <div className={styles.content}>
                {
                  userType === 'password' &&
                  this.templatePasswrod()
                }
              </div>
            </div>
          </div>
        }
        {
          isUserDialog && userType === 'bind_phone' &&
          this.templatBindPhone()
        }
        {
          isUserDialog && userType === 'realname' &&
          this.templatRealname()
        }

        <Dialog
          open={isGoBack}
          onClose={() => this.setState({ isGoBack: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"提示信息?"}</DialogTitle>
          <DialogContent style={{ width: 300 }}>
            <DialogContentText id="alert-dialog-description">
              确认退出登录?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className={styles.dialog_btn} onClick={() => this.setState({ isGoBack: false })}>
              取消
            </div>
            <div className={styles.dialog_btn} onClick={handleLogout}>
              确认
            </div>
          </DialogActions>
        </Dialog>
      
        <Snackbar
          open={isAlert}
          autoHideDuration={MESSAGE_TIME}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => this.setState({ isAlert: false })}
        >
          <MuiAlert
            elevation={6} variant="filled"
            onClose={() => this.setState({ isAlert: false })}
            severity={alertType}
          >
            { alertContent }
          </MuiAlert>
        </Snackbar>

        <Loading isLoading={isLoading} />
      </>
    )
  }
}

export default withRouter(Floatmod)