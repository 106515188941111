import { Component } from 'react'
import { debounce, getUrlParam, handleClearStore, handleGesture, handleGetGameId, handleGetUserInfo, handleIsWechat, handleParam, handlePhonePopup, IPhonePopupType } from '../../utils/utils'
import { getLocalStorage, getStore, removeLocalStorage, removeStore, setLocalStorage, setStore } from '../../utils/store'
import { BIND_PHONE, MESSAGE_TIME, IDENTITY, TOKEN, GAME_SID, GAME_IFRAME_URL, CODE, GAME_ID, OPEN_ID, URL_OPTENID, NOT_GAME_URL, ROLE_ID, ROLE_NAME, LOGIN_TYPE, IS_ADULT, NAME_VERIFY, CHECK_BIND_POPUP, USERNAME, GAME_NAME } from '../../utils/config';
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Loading from '../../components/Loading'
import styles from './index.module.css'
import MuiAlert, { Color } from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Pay from './components/Pay'
import Floatmod from './components/Floatmod'
import API from '../../services/api'
import { handleInitConfig } from 'src/utils/load';
import FloatmodRealname from './components/FloatmodRealname'
import FloatmodBindPhone from './components/FloatmodBindPhone'
import NotAdult from './components/NotAdult'

type IState = {
  isLoading: boolean;
  isAlert: boolean;
  isFloatmod: boolean;
  isPay: boolean;
  isRealname: boolean; // 实名验证
  isBindPhone: boolean; // 是否绑定手机
  alertType: Color;
  alertContent: string;
  amount: number;
  payParams: any;
  gameId: string;
  gameUrl: string;
  openID: string;
  interfaces: string[];
}

class Home extends Component<RouteComponentProps, IState> {
  constructor(props: RouteComponentProps) {
    super(props)
    
    this.state = {
      isFloatmod: true,
      isLoading: false,
      isAlert: false,
      isPay: false,
      isBindPhone: false,
      isRealname: getStore(IS_ADULT) !== 'true' &&
                  getStore(NAME_VERIFY) !== '0' && 
                  getStore(IDENTITY) !== 'true',
      alertType: 'success',
      gameId: '',
      gameUrl: '',
      alertContent: '',
      openID: '',
      amount: 0,
      interfaces: [],
      payParams: {}
    }

    this.handlePayClose = this.handlePayClose.bind(this)
    this.handleOrderState = this.handleOrderState.bind(this)
    this.handleError = this.handleError.bind(this)
    this.handleVerifyCode = this.handleVerifyCode.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.handleFinishLogin = this.handleFinishLogin.bind(this)
    this.handleUrlSearch = this.handleUrlSearch.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleFailedLogin = this.handleFailedLogin.bind(this)
    this.handleLoading = this.handleLoading.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.handleRealnameDialog = this.handleRealnameDialog.bind(this)
    this.handleCloseBindPhone = this.handleCloseBindPhone.bind(this)
    this.handleShowPhonePopup = this.handleShowPhonePopup.bind(this)
  }
  
  orderState: any = null
  payChild: any = null

  componentDidMount() {
    document.title = `${process.env.REACT_APP_COMPANY_NAME}-首页`
    const token = getStore(TOKEN)
    if ((!token || !getLocalStorage(CODE)) || (handleIsWechat() && !getStore(OPEN_ID))) return this.handleLogout()
    let gameId = getStore(GAME_ID) || handleGetGameId(this.props) || ''
    this.setState({ gameId })
    this.setState({ openID: getUrlParam('openid') || '' })
    // 获取初始化配置防止无法登陆
    if (gameId) handleInitConfig(gameId, () => this.handleError({ message: '' }), (res) => {
      // 是否显示绑定手机弹窗
      this.handleShowPhonePopup()
      this.setState({
        isRealname: getStore(IS_ADULT) !== res.name_verify &&
                    getStore(NAME_VERIFY) !== '0' && 
                    getStore(IDENTITY) !== 'true'
      })
    })
    
    if (getStore(GAME_IFRAME_URL)) {
      this.setState({ gameUrl: getStore(GAME_IFRAME_URL) })
      removeStore(GAME_IFRAME_URL)
    } else {
      if (!getLocalStorage(USERNAME) || !handleGetUserInfo().password || !getLocalStorage(CODE)) {
        return this.handleLogout()
      }
      const params = {
        username: getLocalStorage(USERNAME),
        password: handleGetUserInfo().password,
        code: getLocalStorage(CODE),
        openid: getStore(OPEN_ID),
      }
      // 登录获取游戏逻辑
      this.handleLogin(params, getLocalStorage(LOGIN_TYPE))
    }
    setTimeout(() => {
      this.handleSendGameMeg()
    }, 100);

    // 监听message
    window.addEventListener('message', e => {
      // console.log('上报接口:', e)
      let action = e.data.action
      const info = e.data.info
      
      switch(action) {
        // 登录
        case process.env.REACT_APP_CONFIG_LOGIN:
          return this.handleLogin(info)

        // 注销
        case process.env.REACT_APP_CONFIG_LOGIN_OUT:
          return this.handleLogout()

        // 创角
        case process.env.REACT_APP_CONFIG_ROLE:
          setStore(ROLE_ID, info.role_id)
          setStore(ROLE_NAME, info.role_name)
          setStore(GAME_SID, info.game_sid)
          return this.handleRole(info)

        // 显示/隐藏浮窗
        case process.env.REACT_APP_CONFIG_SHOW:
          return this.handleShow(info)

        // 支付
        case process.env.REACT_APP_CONFIG_RECHARGE:
          return info && !this.state.isPay && this.handleRecharge(info)

        default:
          break
      } 
    })

    // IOS禁止缩放
    handleGesture()
  }

  componentDidUpdate() {
    const { gameId } = this.state
    const gameIdStore = handleGetGameId(this.props)
    if (!gameIdStore) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '未获取到游戏ID，请重试!' })
    if (gameIdStore !== gameId && gameIdStore) {
      setStore(GAME_ID, gameId)
      handleInitConfig(gameIdStore, res => this.handleError(res), (res) => {
        // 是否显示绑定手机弹窗
        this.handleShowPhonePopup()
        this.setState({
          isRealname: getStore(IS_ADULT) !== res.name_verify &&
                      getStore(NAME_VERIFY) !== '0' && 
                      getStore(IDENTITY) !== 'true'
        })
      })
      this.setState({ gameId: gameIdStore })
    }
    // 微信登录
    if (handleIsWechat() && !getStore(OPEN_ID)) {
      return this.handleLogout()
    } 
    if (handleIsWechat() && getStore(OPEN_ID)) {
      console.log('首次进入有openid则返回')
      setStore(URL_OPTENID, '0')
    }
  }

  componentWillUnmount() {
    this.orderState = null
  }

  // 错误处理
  handleError(response?: any) {
    return this.setState({ isAlert: true, alertType: 'error', alertContent: response?.message || '服务器错误!' })
  }

  // 处理登录的search字段
  handleUrlSearch() {
    let suffix = ``
    const { search } = this.props.location
    const searchArr = search?.slice(1)?.split('&') || []
    searchArr?.length > 0 && searchArr.forEach((item, index) => {
      const arr = item.split('=')
      // 去除链接参数中的openid
      if (arr[0] !== 'openid') {
        suffix += index === 0 ? `?${arr[0]}=${arr[1]}` : `&${arr[0]}=${arr[1]}`
      }
    })
    return suffix
  }

  // 处理是否显示手机弹窗
  handleShowPhonePopup() {
    if (getStore(CHECK_BIND_POPUP) === 'true' && getStore(BIND_PHONE) !== 'true') {
      this.setState({ isLoading: true })
      API.find_phone_popup_check(handleParam()).then((response: any) => {
        this.setState({ isLoading: false })
        const res = response.data

        if (res.code === 200 && res.data.hasOwnProperty('bind_popup')) {
          const { popup, type, days, section } = res.data.bind_popup
  
          if (popup) {
            switch (type as IPhonePopupType) {
              case 'days_after_register': {
                const value = handlePhonePopup('days_after_register')
                if (value !== days) this.setState({ isBindPhone: true })
                handlePhonePopup('days_after_register', days)
                break
              }
    
              case 'amount': {
                const value = handlePhonePopup('amount')
                if (value !== section) this.setState({ isBindPhone: true })
                handlePhonePopup('amount', section)
                break
              }
            }
          }
        }
      }).catch(() => {
        this.setState({ isLoading: false })
      })
    }
  }

  // 登录获取游戏逻辑
  handleLogin(params: any, type: string = 'login') {
    if (type === 'phone_login') return this.handleFailedLogin()

    this.setState({ isLoading: true })
    API.login(params).then(response => {
      const res = response.data
      if (res.code === 200) {
        this.handleFinishLogin(res)
      }
    }).catch(() => {
      this.handleFailedLogin()
    })
  }

  // 处理登录成功
  handleFinishLogin(response: any) {
    this.setState({ isLoading: false })
    if (response?.code === 200) {
      const { token, username, identity, bind_phone, enter_url, is_adult } = response.data
      
      setStore(TOKEN, token)
      setStore(IDENTITY, identity)
      setStore(BIND_PHONE, bind_phone)
      setStore(GAME_IFRAME_URL, enter_url || '')
      setStore(IS_ADULT, is_adult || false)
      setLocalStorage(USERNAME, username)

      this.setState({ gameUrl: enter_url || NOT_GAME_URL })
    } else {
      this.handleFailedLogin()
    }
  }

  // 处理登录失败
  handleFailedLogin() {
    removeLocalStorage(USERNAME)
    removeLocalStorage(LOGIN_TYPE)
    this.setState({ isLoading: false })
    this.handleLogout()
  }

  // 判断是否存在code字段
  handleVerifyCode() {
    const code = getLocalStorage(CODE)
    if (!code) {
      this.setState({ isLoading: true })
      return API.active().then((response: any) => {
        this.setState({ isLoading: false })
        const res = response.data
        if (res?.code === 200) {
          const { code } = res.data
          setLocalStorage(CODE, code)
          if (handleIsWechat() && !getStore(OPEN_ID)) {
            this.handleLogout()
          }
        }
      }).catch(() => {
        this.setState({ isLoading: false })
      })
    }
  }
    
  // 向游戏传输数据
  handleSendGameMeg() {
    // const token = getStore(TOKEN)
    const game_id = getStore(GAME_ID)
    const username = getLocalStorage(USERNAME)
    const identity = getStore(IDENTITY)
    const timestamp = new Date().getTime()
    const params = { username, identity, game_id, timestamp }
    window?.frames?.['game' as any]?.postMessage({ action: 'user_info', info: params }, '*')
  }

  // 创角类型处理
  handleRoleType(type: number) {
    switch(type) {
      case 1:
        return 'register'

      case 2:
        return 'login'

      case 3:
        return 'level_up'
    }
  }

  // 创角/进服
  handleRole(param: any) {
    this.setState({ isLoading: true })
    const newParams = Object.assign({}, param)

    // 数据类型转换
    newParams.type = this.handleRoleType(parseInt(param?.type || 0))
    newParams.role_id = newParams?.role_id ? newParams.role_id.toString() : ''
    newParams.role_name = newParams?.role_name ? newParams.role_name.toString() : ''
    newParams.role_level = newParams?.role_level ? newParams.role_level.toString() : '0'
    newParams.game_sid = newParams?.game_sid ? newParams.game_sid.toString() : ''
    
    this.setState({ isLoading: true })
    API.role(newParams).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        console.log('创角/进服:', res.message || '成功!')
      } else {
        this.handleError(res)
      }
      this.setState({ isLoading: false })
    }).catch(() => {
      this.handleError()
      this.setState({ isLoading: false })
    })
  }

  // 注销
  handleLogout() {
    const { search } = this.props.location
    handleClearStore()
    handleIsWechat() && this.handleVerifyCode()
    this.props.history.push(`/login${search}`)
  }

  // 支付组件Ref
  onPayRef = (ref: any) => {
    this.payChild = ref
  }

  // 支付预处理
  handleRechargeOption = debounce((amount: any, payParam?: any) => {
    const param = {
      game_id: getStore(GAME_ID),
      game_sid: getStore(GAME_SID),
      amount
    }
    this.setState({ isLoading: true })
    
    !this.state.isPay && API.pay_option(param).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        this.setState({ isLoading: false })
        const interfaces = res.data?.interface || []
        const game_name = res.data?.game_name || ''
        this.setState({ interfaces, amount })

        // 设置游戏名
        setStore(GAME_NAME, game_name)
        
        // 小程序环境获取支付参数
        if ((window as any).__wxjs_environment) {
          return this.payChild.handlePayFun('mini_program_wechat', payParam)
        }
        this.setState({ isPay: true })
      } else {
        this.handleError(res)
      }
    }).catch(() => {
      this.handleError()
      this.setState({ isLoading: false })
    })
  }, 1000)
  
  // 关闭支付弹窗
  handlePayClose() {
    this.setState({ isPay: false })
    clearInterval(this.orderState)
    this.orderState = null
  }

  // 支付
  handleRecharge = debounce((param: any) => {
    // console.log('handleRecharge:', param)
    if (!param) return false
    param.role_id = param?.role_id ? param.role_id.toString() : ''
    param.role_level = param?.role_level ? param.role_level.toString() : '0'
    param.openid = param?.openid ? param.openid.toString() : ''
    param.amount = param?.amount ? parseFloat(param.amount) : ''
    
    this.handleRechargeOption(param.amount, param)
    this.setState({ payParams: param || {} })
  }, 100)
  
  // 轮询订单状态
  handleOrderState(order_id: string) {
    let time = 180 // 三分钟
    const params = { order_id }
    clearInterval(this.orderState)
    this.orderState = setInterval(() => {
      time -= 30
      API.pay_status(params).then((response: any) => {
        const res = response.data
        if (res.code === 200) {
          const { status } = res.data
          if (status === 1) {
            this.setState({ isPay: false })
            clearInterval(this.orderState)
          }
        }
      })

      // 时间归零清空
      if (time === 0) {
        clearInterval(this.orderState)
        this.orderState = null
        this.setState({ isPay: false })
      }
    }, 30000);
  }

  // 显示/隐藏浮窗
  handleShow(info: any) {
    this.setState({ isFloatmod: info })
  }

  // loading
  handleLoading(isLoading: boolean) {
    this.setState({ isLoading })
  }

  // 提示
  handleMessage(content: string, type: Color = 'warning') {
    this.setState({ isAlert: true, alertType: type, alertContent: content })
  }

  // 显示实名验证码
  handleRealnameDialog(isShow: boolean) {
    this.setState({ isRealname: isShow })
  }

  // 显示手机验证
  handleCloseBindPhone(isShow: boolean) {
    this.setState({ isBindPhone: isShow })
  }

  render() {
    const {
      isFloatmod,
      isLoading,
      isAlert,
      isPay,
      isRealname,
      isBindPhone,
      alertType,
      amount,
      openID,
      alertContent,
      payParams,
      interfaces,
      gameUrl
    } = this.state

    return (
      <div style={{ overflow: 'hidden', height: '100%' }}>
        {
          isFloatmod &&
          <Floatmod
            handleVerifyCode={this.handleVerifyCode}
            handleLogout={this.handleLogout}
            handleCloseRealname={this.handleRealnameDialog}
            handleCloseBindPhone={this.handleCloseBindPhone}
          />
        }

        <iframe
          className={styles.iframe}
          src={gameUrl}
          // src='../../../game.html'
          title='game'
          name="game"
          frameBorder={0}
          scrolling="no"
          allowTransparency={true}
          width="100%"
          height="100%"
        ></iframe>

        {
          isRealname &&
          <FloatmodRealname
            zIndex={9}
            isNotClose={getStore(NAME_VERIFY) === '2'}
            handleError={this.handleError}
            handleLoading={this.handleLoading}
            handleMessage={this.handleMessage}
            handleDialog={this.handleRealnameDialog}
          />
        }

        {
          isBindPhone &&
          <FloatmodBindPhone
            zIndex={9}
            handleError={this.handleError}
            handleLoading={this.handleLoading}
            handleMessage={this.handleMessage}
            handleDialog={this.handleCloseBindPhone}
          />
        }

        {
          getStore(IS_ADULT) !== 'true' &&
          getStore(IDENTITY) === 'true' &&
          <NotAdult handleLogout={this.handleLogout} />
        }

        <div className={styles.box}>
          <Pay
            isPay={isPay}
            amount={amount}
            onRef={this.onPayRef}
            interfaces={interfaces}
            payParams={payParams}
            openID={openID}
            close={this.handlePayClose}
            handleError={this.handleError}
            handleOrderState={this.handleOrderState}
          />

          <Snackbar
            open={isAlert}
            autoHideDuration={MESSAGE_TIME}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => this.setState({ isAlert: false })}
          >
            <MuiAlert
              elevation={6} variant="filled"
              onClose={() => this.setState({ isAlert: false })}
              severity={alertType}
            >
              { alertContent }
            </MuiAlert>
          </Snackbar>
          <Loading isLoading={isLoading} />
        </div>
      </div>
    )
  }
}

export default withRouter(Home)
