import { Component } from 'react'
import styles from './index.module.css'
import Loading from '../../components/Loading'
import Checkbox from '@material-ui/core/Checkbox'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { validMobile, handleRandom, handleGetGameId, getUrlParam, handleIsWechat, intervalTime, handleGetUserInfo, handleGesture } from '../../utils/utils'
import { getLocalStorage, getStore, setLocalStorage, setStore } from '../../utils/store'
import { BIND_PHONE, CODE, GAME_ID, GAME_IFRAME_URL, IDENTITY, IS_ADULT, LOGIN_TYPE, MESSAGE_TIME, OPEN_ID, PRIVACY_AGREEMENT, REGISTER_DISABLED, TOKEN, URL_OPTENID, USERNAME, USERNAME_LIST, USER_INFOS } from '../../utils/config'
import { handleInitConfig, handleIsActivityUrl } from '../../utils/load'
import API from '../../services/api'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { Color } from '@material-ui/lab/Alert'
import refreshPng from '../../assets/refresh.png'

type IType = 'phone_login' | 'login' | 'register'
interface IUsername { [key: string]: number }
type IUsernameList = { username: string, time: number };
type IState = {
  isLoading: boolean;
  isSendVerify: boolean,
  isShowPassword: boolean;
  isCheck: boolean;
  isAlert: boolean;
  isActive: boolean;
  title: string;
  type: IType;
  gameId: string;
  username: string;
  password: string;
  selectUsername: string;
  usernamePlaceholder: string;
  passwordPlaceholder: string;
  alertType: Color;
  sendVerifyContent: string,
  alertContent: string;
  openID: string;
  isUsername: boolean;
  usernameList: IUsernameList[];
  time: any;
}


class Login extends Component<RouteComponentProps, IState> {
  constructor(props: RouteComponentProps) {
    super(props)

    this.handleSendVerify = this.handleSendVerify.bind(this)
    this.handleSwitchType = this.handleSwitchType.bind(this)
    this.handleSubimt = this.handleSubimt.bind(this)
    this.handleForget = this.handleForget.bind(this)
    this.handleFirstEntry = this.handleFirstEntry.bind(this)
    this.handleVerifyCode = this.handleVerifyCode.bind(this)
    this.handleSaveUsername = this.handleSaveUsername.bind(this)
    this.handleUrlSearch = this.handleUrlSearch.bind(this)
    this.handleFirstWechat = this.handleFirstWechat.bind(this)
    this.handleError = this.handleError.bind(this)
    this.hanldeActiveAPI = this.hanldeActiveAPI.bind(this)
    this.handleLasterLogin = this.handleLasterLogin.bind(this)
  }

  state: IState = {
    isLoading: false,
    isShowPassword: false,
    isSendVerify: false,
    isAlert: false,
    isCheck: false,
    isUsername: false,
    isActive: false,
    gameId: '',
    type: (getLocalStorage(LOGIN_TYPE) !== 'register' ? getLocalStorage(LOGIN_TYPE) : 'login')  as IType || 'login', // phone_login login register
    title: '账号登录',
    username: getLocalStorage(USERNAME),
    password: handleGetUserInfo().password,
    selectUsername: '',
    usernamePlaceholder: '请输入用户名',
    passwordPlaceholder: '请输入密码',
    alertType: 'warning',
    alertContent: '',
    sendVerifyContent: '',
    openID: getStore(OPEN_ID),
    usernameList: [],
    time: null,
  }

  componentDidMount() {
    document.title = `${process.env.REACT_APP_COMPANY_NAME}-登录`
    
    this.handleFirstEntry();

    // IOS禁止缩放
    handleGesture()
  }

  componentDidUpdate() {
    const gameId = handleGetGameId(this.props)
    if (gameId !== this.state.gameId && gameId) {
      handleInitConfig(gameId, res => this.handleError(res))
      setStore(GAME_ID, gameId)
      return this.setState({ gameId })
    }
    if (handleIsWechat() && !getStore(OPEN_ID) && getLocalStorage(CODE)) {
      return this.handleFirstWechat(getStore(GAME_ID))
    }
    if (handleIsWechat() && getStore(OPEN_ID)) {
      console.log('首次进入有openid则返回')
      setStore(URL_OPTENID, '0')
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.time)
    this.setState({ time: null })
  }

  // 获取最后的用户
  handleLasterLogin(data: IUsername): string {
    let lasterUsername = ''
    let max = 0
  
    for (let key in data) {
      if (max < data[key]) {
        max = data[key]
        lasterUsername = key
      }
    }

    return lasterUsername
  }

  // 首次进入
  handleFirstEntry() {
    const { search } = this.props.location
    const usernameStore = getLocalStorage(USERNAME_LIST)
    const usernames: IUsername = usernameStore ? JSON.parse(usernameStore) : ''
    const lastUsernames = this.handleLasterLogin(usernames)
    this.setState({ username: lastUsernames, selectUsername: lastUsernames })
    const gameId = handleGetGameId(this.props)
    if (gameId !== this.state.gameId && gameId) {
      setStore(GAME_ID, gameId)
      handleInitConfig(gameId, res => this.handleError(res))
      this.setState({ gameId })
    }
    if (gameId && !getLocalStorage(CODE)) {
      this.handleVerifyCode()
    }
    const token = getStore(TOKEN)
    if (token && !handleIsWechat() && getLocalStorage(LOGIN_TYPE) !== 'phone_login') {
      return this.props.history.push(`/index${search}`)
    }
    
    // 首次进入类型手机登录清空验证码
    if (getLocalStorage(LOGIN_TYPE) === 'phone_login') {
      this.setState({ password: '' })
    }

    // 获取存储username
    if (usernames) {
      const usernameList: IUsernameList[] = []
      for (let key in usernames) {
        usernameList.push({
          username: key,
          time: usernames[key]
        })
      }
      usernameList.sort((a, b) => a.time - b.time)
      this.setState({ usernameList })
    }
  }

  // 首次进入微信内置浏览器
  handleFirstWechat(gameId: string, code: string = getLocalStorage(CODE)) {
    if (handleIsWechat()) {
      const openID = getUrlParam('openid')
      const openidStore = getStore(OPEN_ID)
      const urlStore = getStore(URL_OPTENID)

      // 首次进入有openid则返回
      if (getStore(OPEN_ID)) {
        console.log('首次进入有openid则返回')
        return setStore(URL_OPTENID, '0')
      }

      // 首次进入带openid页面，没有缓存则重新授权 或 首次进入没有openid和缓存
      if ((openID && !urlStore && urlStore !== '1') || (!openID && !openidStore)) {
        const params = {
          code,
          game_id: gameId || getStore(GAME_ID),
          channel: getUrlParam('channel') || ''
        }
        setStore(URL_OPTENID, '1')
        console.log('首次进入带openid页面，没有缓存则重新授权 或 首次进入没有openid和缓存')
        return window.open(API.wx_oauth(params))
      }

      // 请求过授权但链接没有返回openid
      if (!openID && urlStore === '1' && !openidStore) {
        setStore(URL_OPTENID, '0')
        console.log('请求过授权但链接没有返回openid')
      }

      // 有openid且没有缓存时赋值
      if (openID && urlStore === '1' && !openidStore) {
        setStore(URL_OPTENID, '0')
        setStore(OPEN_ID, openID)
        this.setState({ openID })
        console.log('有openid且没有缓存时赋')
      }
    }
  }
  
  // 判断是否存在code字段
  handleVerifyCode() {
    const code = getLocalStorage(CODE)
    if (!code) {
      return this.hanldeActiveAPI()
    }
  }

  // 处理激活接口
  async hanldeActiveAPI(isSubmit?: boolean) {
    this.setState({ isLoading: true })
    return await API.active().then((response: any) => {
      const res = response.data
      this.setState({ isLoading: false })
      if (res?.code === 200) {
        const { code } = res.data
        setLocalStorage(CODE, code)
        if (handleIsWechat() && !getStore(OPEN_ID)) {
          this.handleFirstWechat(getStore(GAME_ID), code)
        }
        if (isSubmit) this.handleSubimt()
      } else {
        this.handleError(res)
      }
    }).catch(() => {
      this.setState({ isLoading: false })
    })
  }

  // 处理登录的search字段
  handleUrlSearch() {
    let suffix = ``
    const { search } = this.props.location
    const searchArr = search?.slice(1)?.split('&') || []
    searchArr?.length > 0 && searchArr.forEach((item, index) => {
      const arr = item.split('=')
      // 去除链接参数中的openid
      if (arr[0] !== 'openid') {
        suffix += index === 0 ? `?${arr[0]}=${arr[1]}` : `&${arr[0]}=${arr[1]}`
      }
    })
    return suffix
  }

  // 错误处理
  handleError(response: any) {
    const { isActive } = this.state
    if ((response.message as string).includes('未激活') && !isActive) {
      this.setState({ isActive: true })
      return this.hanldeActiveAPI(true)
    }
    return this.setState({ isAlert: true, alertType: 'error', alertContent: response?.message || '服务器错误!' })
  }

  // 发送验证码
  handleSendVerify() {
    const { username } = this.state

    let gameId = getStore(GAME_ID)
    if (!gameId) {
      gameId = handleGetGameId(this.props)
      setStore(GAME_ID, gameId)
      if (!gameId) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '未获取到游戏ID，请重试!' })
    }
    if (!username) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '请输入手机号' })
    if (!validMobile(username)) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '请输入正确手机号' })

    const params = { type: 'auth', phone: username }
    this.setState({ isLoading: true })
    API.phone_verify(params).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        this.setState({ isAlert: true, alertType: 'success', alertContent: '发送成功' })
        this.handleCountdown()
      }
      this.setState({ isLoading: false })
    }).catch(() => {
      this.setState({ isLoading: false })
    })
  }

  // 倒计时60秒
  handleCountdown(date = 60000) {
    let num = date / 1000
    this.setState({
      isSendVerify: true,
      sendVerifyContent: `等待${num}s`,
      time: setInterval(() => {
        num--
        this.setState({ sendVerifyContent: `等待${num}s` })
        if (num === 0) {
          clearInterval(this.state.time)
          this.setState({ isSendVerify: false })
        }
      }, 1000)
    })
  }

  // 更换类型
  handleSwitchType(type: IType) {
    const { selectUsername } = this.state
    this.setState({ type, username: '', password: '' })

    switch(type) {
      case 'phone_login':
        this.setState({
          isCheck: false,
          title: '手机登录',
          usernamePlaceholder: '请输入手机号码',
          passwordPlaceholder: '请输入验证码',
        })
        break

      case 'register':
        this.setState({
          isCheck: false,
          title: '一键注册',
          usernamePlaceholder: '请输入用户名',
          passwordPlaceholder: '请输入密码',
          username: `o_${handleRandom(7)}`,
          password: handleRandom(6)
        })
        break
        
      default:
        this.setState({
          isCheck: true,
          title: '账号登录',
          usernamePlaceholder: '请输入用户名',
          passwordPlaceholder: '请输入密码',
          username: selectUsername || '',
          password: handleGetUserInfo(selectUsername).password
        })
        break
    }
  }

  // 忘记密码
  handleForget() {
    const { search } = this.props.location
    this.props.history.push(`/forget${search}`)
  }

  // 确认登录
  async handleSubimt() {
    const { username, password, isCheck, type } = this.state
    // 微信登录没有OpenID禁止登录
    if (handleIsWechat() && !getStore(OPEN_ID)) {
      return this.setState({ isAlert: true, alertType: 'warning', alertContent: '未获取OpenID,请重试!' })
    }
    let gameId = getStore(GAME_ID)
    const code = getLocalStorage(CODE)
    const params: any = { username, password, code }
    if (handleIsWechat()) params.openid = getStore(OPEN_ID)
    if (!gameId) {
      gameId = handleGetGameId(this.props)
      setStore(GAME_ID, gameId)
      if (!gameId) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '未获取到游戏ID，请重试!' })
    }
    if (!username || !password) return this.setState({ isAlert: true, alertType: 'warning', alertContent: '请输入完整信息' })
    if (!isCheck && type !== 'login') return this.setState({ isAlert: true, alertType: 'warning', alertContent: '请勾选用户协议' })
    // 当没有code重新获取
    if (!getLocalStorage(CODE)) {
      await this.handleVerifyCode()
    }

    this.setState({ isLoading: true })
    
    switch(type) {
      case 'phone_login':
        params.phone = username
        params.verify = password
        if (!validMobile(username)) {
          this.setState({ isLoading: false })
          return this.setState({ isAlert: true, alertType: 'warning', alertContent: '请输入正确手机号' })
        }
        API.phone_auth(params).then(response => {
          const res = response.data
          this.handleFinishLogin(res, type)
        }).catch(() => {
           this.setState({ isLoading: false })
        })
        break

      case 'login':
        API.login(params).then(response => {
          const res = response.data
          this.handleFinishLogin(res, type)
        }).catch(() => {
          this.setState({ isLoading: false })
        })
        break

      case 'register':
        API.register(params).then(response => {
          const res = response.data
          this.handleFinishLogin(res, type)
        }).catch(() => {
          this.setState({ isLoading: false })
        })
        break
    }
  }

  // 保存用户名和时间戳
  handleSaveUsername(username: string) {
    let usernameStore: { [key: string]: number } = {}
    const time = new Date().getTime()
    if (getLocalStorage(USERNAME_LIST)) {
      usernameStore = JSON.parse(getLocalStorage(USERNAME_LIST) as string)
      usernameStore[username] = time
    } else {
      usernameStore = { [username]: time }
    }

    const arr: { username: string; time: number }[] = []
    // 用户数据只保存5个
    for (let key in usernameStore) {
      arr.push({
        username: key,
        time: usernameStore[key]
      })
    }
    arr.sort((a, b) => b.time - a.time)
    let newStore: { [key: string]: number } = {}
    if (arr.length > 5) arr.length = 5
    arr.forEach(item => newStore[item.username] = item.time)
    setLocalStorage(USERNAME_LIST, JSON.stringify(newStore))
  }

  // 处理登录/注册成功事件
  handleFinishLogin(response: any, type: string) {
    const { search } = this.props.location
    if (response?.code === 200) {
      const { token, username, identity, bind_phone, enter_url, is_adult } = response.data
      const user_infos: IUserInfos[] = getLocalStorage(USER_INFOS) ? JSON.parse(getLocalStorage(USER_INFOS)) : []
      const { password } = this.state
      const time = new Date().getTime()
      let isExistUsername = false // 是否存在用户数据
      for (let key in user_infos) {
        if (user_infos[key].username === username) {
          isExistUsername = true
          user_infos[key].password = password
          user_infos[key].time = time
        }
      }
      if (!isExistUsername) user_infos.push({
        username, password, time, days: 0, amount: 0
      })
      
      setStore(TOKEN, token)
      setStore(IDENTITY, identity)
      setStore(BIND_PHONE, bind_phone)
      setStore(GAME_IFRAME_URL, enter_url || '')
      setStore(IS_ADULT, is_adult || false)
      setLocalStorage(USERNAME, username)
      setLocalStorage(USER_INFOS, JSON.stringify(user_infos))
      setLocalStorage(LOGIN_TYPE, type)

      // 判断是否显示活动
      handleIsActivityUrl()
      
      // 保存多个用户名和时间戳
      this.handleSaveUsername(username)
      this.props.history.push(`/index${search}`)
    } else {
      this.handleError(response)
    }
    this.setState({ isLoading: false })
  }


  render() {
    const {
      username,
      password,
      isLoading,
      isSendVerify,
      title,
      usernamePlaceholder,
      isShowPassword,
      passwordPlaceholder,
      sendVerifyContent,
      type,
      isAlert,
      alertType,
      alertContent,
      usernameList,
      isUsername
    } = this.state

    return (
      <div className={styles.body}>
        <div className={styles.box}>
          <div className={styles.title} id="title">{ title }</div>
          <div className={styles.content}>
            <div className={styles.row}>
              <input
                className={styles.input}
                type="text"
                placeholder={usernamePlaceholder}
                value={username}
                onChange={e => this.setState({ username: e.target.value })}
              />
              <div className={styles.username_right}>
                <div className={styles.username_close} onClick={() => this.setState({ username: '' })}>
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor">
                    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                  </svg>
                </div>

                {
                  type === 'login' &&
                  <i
                    className={`${styles.icon_arrow} ${ isUsername ? styles.active : '' }`}
                    onClick={() => this.setState({ isUsername: !isUsername })
                  }></i>
                }

                {
                  isUsername && 
                  type === 'login' &&
                  <div className={styles.username_box}>
                    {
                      usernameList?.length > 0 && usernameList.map(item => (
                        <div
                          className={styles.username_row}
                          onClick={() => this.setState({
                            isUsername: false,
                            username: item.username,
                            selectUsername: item.username,
                            password: handleGetUserInfo(item.username).password
                          })
                        }>
                          <span>{ item.username }</span>
                          <span>{ intervalTime(item.time) }</span>
                        </div>
                      ))
                    }
                  </div>
                }

                {
                  type === 'register' &&
                  <img
                    className={styles.refresh_svg}
                    src={refreshPng}
                    alt="refreshPng"
                    onClick={() => 
                      this.setState({
                        username: `o_${handleRandom(7)}`,
                        password: handleRandom(6)
                      })
                    }
                  />
                }
              </div>
            </div>
            <div className={`${styles.password_row} ${styles.row}`}>
              <input
                className={styles.input}
                type={ isShowPassword ? 'text' : "password"}
                placeholder={passwordPlaceholder}
                value={password}
                onChange={e => this.setState({ password: e.target.value })}
              />
              {
                type !== 'phone_login' &&
                <svg viewBox="0 0 100 100" className={styles.icon} onClick={() => { this.setState({ isShowPassword: !isShowPassword }) }}>
                  <path stroke="#f88e24" fill="none" strokeLinecap="round" strokeWidth="7" d="M10,50 Q50,10 90,50" className="eye-top"></path>
                  <circle fill="none" stroke="#f88e24" strokeWidth="7" cx="50" cy="50" r="15" strokeLinecap="round" className="eye-ball" transform="rotate(90 50 50)"></circle>
                  <path stroke="#f88e24" fill="none" strokeLinecap="round" strokeWidth="7" d="M10,50 Q50,90 90,50" className="eye-bottom"></path>
                  {
                    !isShowPassword &&
                    <line x1="80" y1="20" x2="20" y2="80" stroke="#f88e24" strokeWidth="7" />
                  }
                </svg>
              }
              { 
                type === 'phone_login' && !isSendVerify &&
                <div className={styles.send_verify} onClick={this.handleSendVerify}>发送验证码</div>
              }
              { 
                type === 'phone_login' && isSendVerify &&
                <div className={styles.time}>{ sendVerifyContent }</div>
              }
              { 
                type === 'login' &&
                <div id="forget" className={styles.forget} onClick={this.handleForget}>忘记密码</div>
              }
            </div>
            { 
              type !== 'login' &&
              <div id="agreement" className={styles.agreement}>
                <Checkbox
                  color='primary'
                  checked={this.state.isCheck}
                  style={{ color: '#f88e24' }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ isCheck: e.target.checked})}
                />
                <span>我已阅读并同意</span>
                <span
                  id="privacy_agreement"
                  className={styles.privacy_agreement}
                  onClick={() => window.open(PRIVACY_AGREEMENT)}
                >
                  用户协议及隐私协议
                </span>
              </div>
            }
            <div className={styles.row}>
              <button
                className={styles.btn}
                type="submit"
                onClick={this.handleSubimt}
              >
                { type === 'register' ? '立即注册' : '立即登录' }
              </button>
            </div>
            <div className={styles.row_footer}>
              <div
                className={type === 'phone_login' ?  styles.active : '' }
                onClick={() => this.handleSwitchType('phone_login')}
              >
                手机登录
              </div>
              <div
                className={type === 'login' ?  styles.active : '' }
                onClick={() => this.handleSwitchType('login')}
              >
                已有账号
              </div>
              {
                parseInt(getStore(REGISTER_DISABLED)) !== 1 &&
                <div
                  className={type === 'register' ?  styles.active : '' }
                  onClick={() => this.handleSwitchType('register')}
                >
                  一键注册
                </div>
              }
            </div>
          </div>
        </div>

        <Snackbar
          open={isAlert}
          autoHideDuration={MESSAGE_TIME}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => this.setState({ isAlert: false })}
        >
          <MuiAlert
            elevation={6} variant="filled"
            onClose={() => this.setState({ isAlert: false })}
            severity={alertType}
          >
            { alertContent }
          </MuiAlert>
        </Snackbar>

        <Loading isLoading={isLoading} />
      </div>
    )
  }
}

export default withRouter(Login)
