import { Color } from '@material-ui/lab'
import { Component } from 'react'
import styles from './FloatmodRealname.module.css'
import API from '../../../services/api'
import { setStore } from 'src/utils/store'
import { IDENTITY, IS_ADULT } from 'src/utils/config'

interface IProps {
  zIndex?: number;
  isNotClose?: boolean;
  handleError: (response?: any) => void;
  handleLoading: (isLoading: boolean) => void;
  handleMessage: (content: string, type?: Color) => void;
  handleDialog: (isShow: boolean) => void;
  handleCloseRealname?: (isDialog: boolean) => void;
}
interface IState {
  realnameName: string;
  realnameId: string;
}

export default class FloatmodRealname extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      realnameName: '',
      realnameId: '',
    }

    this.handleClickRealname = this.handleClickRealname.bind(this)
  }

  // 提交实名认证
  handleClickRealname() {
    const { realnameName, realnameId } = this.state
    const {
      handleError,
      handleLoading,
      handleMessage,
      handleDialog,
      handleCloseRealname
    } = this.props
    
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/

    if (!realnameName) return handleMessage('请输入姓名', 'warning')
    if (!realnameId) return handleMessage('请输入身份证号码', 'warning')
    if (!reg.test(realnameId)) return handleMessage('请输入正确身份证格式!', 'warning')

    const params = { real_name: realnameName, id_card: realnameId }
    handleLoading(true)
    API.user_identity(params).then((response: any) => {
      const res = response.data
      if (res?.code === 200) {
        const { is_adult } = res.data
        handleDialog(false)
        handleMessage(res.message || '成功!', 'success')
        setStore(IDENTITY, 'true')
        setStore(IS_ADULT, is_adult)
        handleCloseRealname && handleCloseRealname(is_adult !== true)
      } else {
        handleError(res)
      }

      handleLoading(false)
    }).catch(() => {
      handleLoading(false)
      handleError()
    })
  }

  render() {
    const { realnameName, realnameId } = this.state
    const { zIndex, isNotClose, handleDialog } = this.props

    return (
      <div className={`${styles.dialog} ${styles.user_dialog}`} style={{ zIndex: zIndex || 10 }}>
        <div className={styles.box}>
          {
            !isNotClose &&
            <div className={styles.dialog_close} onClick={() => handleDialog(false)}>
              <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
              </svg>
            </div>
          }
          <div className={styles.title}>
            <span>实名认证</span>
          </div>
          <div className={styles.content}>
            <div className={styles.realname_row}>
              <span>按照文化部《网络游戏管理暂行办法》的相关要求，网络游戏用户需要使用有效身份证件进行实名认证。谢谢理解与配合！</span>
            </div>
            <div className={styles.user_row}>
              <input
                className={styles.input}
                value={realnameName}
                type="text"
                placeholder="请输入姓名"
                onChange={e => this.setState({ realnameName: e.target.value })}
              />
            </div>
            <div className={styles.user_row}>
              <input
                className={styles.input}
                value={realnameId}
                type="text"
                placeholder="请输入身份证号码"
                onChange={e => this.setState({ realnameId: e.target.value })}
              />
            </div>
            <div className={styles.user_btn_row}>
              <button className={styles.btn} onClick={this.handleClickRealname}>立即认证</button>
            </div>
            <div className={styles.realname_row}>
              <div className={styles.warning}>注意事项:</div>
              <div className={styles.warning}>1、如需要重新认证必须联系客服。</div>
              <div className={styles.warning}>2、可作为账号找回重要证据之一。</div>
              <div className={styles.warning}>3、作为当前账号是否纳入防沉迷的唯一依据。</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
