import request from '../utils/request'
import { handleParam } from '../utils/utils'

// SDK初始化配置活
function sdk_config(params: any) {
  return request.post(`/sdk/config`, handleParam(params))
}

// 激活
function active(params?: any) {
  return request.post(`/device/active`, handleParam(params))
}

// 微信授权
function wx_oauth(params: any) {
  const prefixUrl = process.env.REACT_APP_BASE_API
  const { code, game_id, channel } = params
  let url = `${prefixUrl}/user/wx-oauth?code=${code}&game_id=${game_id}&channel=${channel}`
  return url
}

// 注册
function register(params: any) {
  return request.post(`/user/register`, handleParam(params))
}

// 登录
function login(params: any) {
  return request.post(`/user/login`, handleParam(params))
}

// 手机号码注册/登录
function phone_auth(params: any) {
  return request.post(`/phone/auth`, handleParam(params))
}

// 发送验证码
function phone_verify(params: any) {
  return request.post(`/phone/verify`, handleParam(params))
}

// 忘记密码
function phone_forget(params: any) {
  return request.post(`/phone/forget`, handleParam(params))
}

// 角色注册/登录
function role(params: any) {
  return request.post(`/role/auth`, handleParam(params))
}

// 支付预请求
function pay_option(params: any) {
  return request.post(`/pay/option`, handleParam(params))
}

// 支付
function pay_order(params: any) {
  return request.post(`/pay/order`, handleParam(params))
}

// 小程序支付
function pay_order_mini(params: any) {
  return request.post(`/pay/order-mini`, handleParam(params))
}

// 订单状态查询
function pay_status(params: any) {
  return request.post(`/pay/status`, handleParam(params))
}

// 修改密码
function modify_password(params: any) {
  return request.post(`/user/modify-password`, handleParam(params))
}

// 绑定手机
function phone_binding(params: any) {
  return request.post(`/phone/binding`, handleParam(params))
}

// 实名认证
function user_identity(params: any) {
  return request.post(`/user/identity`, handleParam(params))
}

// 礼包码列表
function gift_bag_list(params?: any) {
  return request.post(`/gift-bag/list`, handleParam(params))
}

// 领取礼包码
function gift_bag_finish(params: any) {
  return request.post(`/gift-bag/finish`, handleParam(params))
}

// 红包列表
function red_bag(params: any) {
  return request.get(`/red-bag/display`, { params: handleParam(params) })
}

// 领取红包
function red_bag_issue(params: any) {
  return request.post(`/red-bag/issue`, handleParam(params))
}

// 获取提取红包现金
function find_red_bag_cash(params: any) {
  return request.get(`/red-bag/cash`, { params: handleParam(params) })
}

// 提取红包现金
function red_bag_cash(params: any) {
  return request.post(`/red-bag/cash`, handleParam(params))
}

// 活动校验是否可参与
function find_activity_check(id: string) {
  return request.get(`/activity/check/${id}`)
}

// 活动详情
function find_activity(id: string) {
  return request.get(`/activity/${id}`)
}

// 是否绑定手机弹窗
function find_phone_popup_check(data: any) {
  return request.post(`/sdk/bind-popup-check`, data)
}

const API = {
  sdk_config,
  active,
  wx_oauth,
  register,
  login,
  phone_auth,
  phone_verify,
  phone_forget,
  role,
  pay_option,
  pay_order,
  pay_order_mini,
  pay_status,
  modify_password,
  phone_binding,
  user_identity,
  gift_bag_list,
  gift_bag_finish,
  red_bag,
  red_bag_issue,
  find_red_bag_cash,
  red_bag_cash,
  find_activity_check,
  find_activity,
  find_phone_popup_check
}

export default API