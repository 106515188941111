import { Route, Switch, withRouter } from "react-router-dom"
import Home from '../pages/home'
import Login from '../pages/login'
import Forget from '../pages/forget'

function Root(props: any) {
  return (
    <Switch>
      <Route exact component={Login} path='/login' />
      <Route exact component={Home} path='/' />
      <Route exact component={Home} path='/index' />
      <Route exact component={Forget} path='/forget' />
      <Route component={Login} />
    </Switch>
  )
}

export default withRouter(Root)