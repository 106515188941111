// 颜色
export const success = 'green';
export const danger = 'red';

// 游戏链接
export const GAME_URL = '/game.html'

// 404链接
export const NOT_GAME_URL = '/404.html'

// 密钥
export const SIGN_KEY = 'bW95dWU='

// 用户隐私协议
export const PRIVACY_AGREEMENT = 'http://www.moyue2020.com/c.html'

// 消息显示时间
export const MESSAGE_TIME = 2000

// localStorage
export const USERNAME = 'username' // 当前用户
export const USERNAME_LIST = 'username_list' // 用户列表
export const USER_INFOS = 'user_infos' // 用户信息
/**
 * USER_INFOS: [{
 *  username: '',
 *  time： '',
 *  password: '',
 *  days: '',
 *  amount: '',
 * }...]
 */
export const LOGIN_TYPE = 'login_type'
export const CODE = 'code'
export const IS_WECHAT_MINI = 'isWechatMini'
export const APP_ID = 'app_id'
export const IDFA = 'idfa'
export const IDFV = 'idfv'
export const IMEI = 'imei'
export const IMEI2 = 'imei2'
export const OAID = 'oaid'
export const ANDROID_ID = 'androidid'
export const MODEL = 'model'
export const NETWORK = 'network'
export const ISP = 'isp'
export const OS = 'os'

// cookies
export const ROLE_ID = 'role_id'
export const ROLE_NAME = 'role_name'
export const GAME_ID = 'game_id'
export const GAME_NAME = 'game_name'
export const GAME_SID = 'game_sid'
export const GAME_SID_NAME = 'game_sid_name'
export const GAME_IFRAME_URL = 'game_iframe_url'
export const TOKEN = 'sdk_token'
export const IDENTITY = 'identity'
export const BIND_PHONE = 'bind_phone'
export const RED_BAG_ACTIVE = 'red_bag_active'
export const EXCHANGE_GROUP = 'exchange_group' // 玩家QQ群
export const GM_QQ = 'gm_qq' // QQ
export const OPEN_ID = 'openid'
export const URL_OPTENID = 'url_openid' // URL不存在openid
export const REGISTER_DISABLED = 'register_disabled' // 一键注册
export const NAME_VERIFY = 'name_verify' // 实名验证
export const IS_ADULT = 'is_adult' // 是否成年
export const GUIDE_URL = 'guide_url' // 攻略
export const ACTIVITY_URL = 'activity_url' // 活动
export const IS_ACTIVITY_URL = 'is_activity_url' // 是否显示活动
export const CHECK_BIND_POPUP = 'check_bind_popup' // 是否显示绑定手机弹窗